

export default  {

    init: function (language, storageService, httpClientService) {
        this.language = language;
        this.service = "language/";
        this.storageService = storageService;
        this.httpClientService = httpClientService;        
    },

    
    getCachedValues: function (url) {
        return this.storageService.getCachedRequest(url).then(result => {
            if (!result)
            {
                result = this.httpClientService.get(url).then(x => {
                    this.storageService.cacheRequest(url, x);
                    return x;
                });
            }
            return result;
        });
    },

    getText: function (resourceId) {
        var url = this.service + "text?l=" + this.language + '&resId='
        url += resourceId;
        return this.getCachedValues(url);
    },
    formatFullDate: function (date, weekDay, month) {
        //for now only english
        return weekDay + ' ' + month + ' ' + date.getDate() + ' ' + date.getFullYear();
    },
    getMonths: function () {
        var url = this.service + "months?l=" + this.language;
        return this.getCachedValues(url);
    },
    getWeekDays: function (shortWeekDays) {
        var url = this.service + (shortWeekDays ? "shortweekdays" : "weekdays") + "?l=" + this.language;
        return this.getCachedValues(url);
    },
}
