
export default {
    GUID_NULL: '00000000-0000-0000-0000-000000000000',
    X_PREFIX: 'XX',
    X_POSTFIX: '-X', //slipped by me, too late
    Dual: "Dual",
    outerLimit: 3.00,
    defaultPageSize: 12,
    quadrantStoragePrefix: 'quadrant_v2',
    signatureResultPrefix: 'signature_result_v2.1',
    cssClassLeftSignature: 'star-left',
    cssClassRightSignature: 'star-right',
    cssClassPlanetStar: 'planet-star',
    circleZoomStep: 50,
    maxCircleDiameter: 800,
    minCircleDiameter: 300,
    startCircleDiameter: 500
};