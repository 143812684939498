import {AllKeysLookup, SignatureTypeEnum, SignatureTypeEnumValues, SignatureTargetEnum} from '@/constants/enums';
import Constants from '@/constants/constants';
import {DegreeLookup, DegreeLookupVM} from '@/models/degreeLookup';
import utilService from '@/services/utilService';
/*
 Bij laden sigs de rowkeys per sig splitsen en in dictionary zetten.
 Key van dictionary is iedere keer een split rowkey, de waarde is dictionary met type signature 
 en een tellertje dat oploopt als er bij hetzelfde type signature nog een bij komt.
 
 Bij laden aspects rowkey splitsen en naast deze dictionaries leggen voor een match.
 */
const _rxHarmonicPlanet = /(?<planet>H[^_]+_[A-Z]{2})/g;
const _rxAspects = /(OP)|(TR\d?)|(SQ\d?)|(QT\d)|(CJ\d?)/g
class CompositeLookup {
    constructor() {
        this.clear();
    }
    clear() {
        this.lookupLeft = {};
        this.lookupRight = {};
        this.lookupRowKeys = {};
        this.signatures = {};
        this.singleChartRows = {};
        this.degreeLookup = new DegreeLookup();
        this.grandStarsPresent = false;
    }

    addSignature(signature, signatureResultRows, storePlanets) {
        var rowKeys = [];
        signatureResultRows.forEach(signatureResultRow => {
            rowKeys.push(signatureResultRow[0].rowKey);
            this.lookupRowKeys[signatureResultRow[0].rowKey] = signatureResultRow[0].originalRowKeys;
        });
        if (storePlanets) {
            signatureResultRows.forEach(signatureResultRow => {
                signatureResultRow.forEach(row => {
                    this.singleChartRows[row.cardEnum + '_' + row.planetEnum] = row;
                })
            });
        }

        var signatureType = signature.signatureType;
        this.signatures[signatureType] = signature;
        if (signatureType == SignatureTypeEnum.Quintile) {
            this.grandStarsPresent = true;
        }
        var shortSignatureType = SignatureTypeEnumValues[signature.signatureType];
        signatureResultRows.forEach(signatureResultRow => {
            var skip = 0;
            var shrunkRowKey = utilService.shrinkRowKey(signatureResultRow[0].rowKey);
            var leftRows = signatureResultRow.filter(row => ++skip == 1 || row.isX).map(row => {
                var lookupVM = new DegreeLookupVM(
                        AllKeysLookup[row.cardEnum],
                        AllKeysLookup[row.planetEnum],
                        shrunkRowKey,
                        true
                        )
                this.degreeLookup.insert(shortSignatureType, row.degree, lookupVM);
                return row;
            });
            skip = 0;
            var rowKey = signatureResultRow[0].rowKey;
            var rightPlanets = signatureResultRow.filter(row => ++skip > 1 && !row.isX).map(row => {
                var lookupVM = new DegreeLookupVM(
                        AllKeysLookup[row.cardEnum],
                        AllKeysLookup[row.planetEnum],
                        shrunkRowKey,
                        false
                        )
                this.degreeLookup.insert(shortSignatureType, row.degree, lookupVM);
                return row.cardEnum + '_' + row.planetEnum;
            });
            leftRows.forEach(leftRow => {
                this.doAddSignature(rowKey, signatureType, leftRow, rightPlanets);
            });
        });
    }

    doAddSignature(rowKey, signatureType, leftRow, rightPlanets) {
        var leftPlanet = leftRow.cardEnum + '_' + leftRow.planetEnum;
        leftPlanet = utilService.shrinkRowKey(leftPlanet);
        rowKey = utilService.shrinkRowKey(rowKey);
        var signaturesLeft = this.lookupLeft[leftPlanet];
        if (!signaturesLeft) {
            signaturesLeft = {};
        }
        var leftSignatures = signaturesLeft[signatureType];
        if (!leftSignatures) {
            leftSignatures = [];
        }
        if (leftSignatures.indexOf(rowKey) < 0) {
            leftSignatures.push(rowKey);
        }
        signaturesLeft[signatureType] = leftSignatures;
        this.lookupLeft[leftPlanet] = signaturesLeft;
        var moveRightToLeft = false;
        var signaturesRight;
        var rightSignatures;
        rightPlanets.forEach(rightPlanet => {
            moveRightToLeft = rightPlanet.startsWith(Constants.X_PREFIX);
            if (moveRightToLeft) {
                rightPlanet = rightPlanet.replace(Constants.X_PREFIX, '');
                rightPlanet = utilService.shrinkRowKey(rightPlanet);
                signaturesRight = this.lookupLeft[rightPlanet];
            } else {
                rightPlanet = utilService.shrinkRowKey(rightPlanet);
                signaturesRight = this.lookupRight[rightPlanet];
            }

            if (!signaturesRight) {
                signaturesRight = {};
            }
            rightSignatures = signaturesRight[signatureType];
            if (!rightSignatures) {
                rightSignatures = [];
            }
            if (rightSignatures.indexOf(rowKey) < 0) {
                rightSignatures.push(rowKey);
            }
            signaturesRight[signatureType] = rightSignatures;
            if (moveRightToLeft) {
                this.lookupLeft[rightPlanet] = signaturesRight;
            } else {
                this.lookupRight[rightPlanet] = signaturesRight;
            }

        });
    }

    getOriginalRowKeys(rowKey) {
        return this.lookupRowKeys[rowKey];
    }

    getSignaturesInDegreeRange(signatureType, degree, range) {
        var shortSignatureType = SignatureTypeEnumValues[signatureType];
        return this.degreeLookup.getRange(shortSignatureType, degree - range, degree + range);
    }

    _expandSignatures(signatures) {
        if (signatures) {
            for (var key in signatures) {
                signatures[key] = signatures[key].map(x => {
                    return utilService.expandRowKey(x);
                });
            }
        }
        return signatures;
    }
    _getLookupLeft(rowKeyLeft) {
        var result = this.lookupLeft[utilService.shrinkRowKey(rowKeyLeft)];
        this._expandSignatures(result);
        return result;
    }

    _getLookupRight(rowKeyRight) {
        var result = this.lookupRight[utilService.shrinkRowKey(rowKeyRight)];
        this._expandSignatures(result);
        return result;
    }

    getSignatures(rowKey) {
        //aspect.rowKey = aspect.sourceCard + '_' + aspect.sourcePlanet + '_' + aspect.matchCard + '_' + aspect.matchPlanet;        
        var parts = rowKey.split('_');
        var rowKeyLeft = parts[0] + '_' + parts[1];
        var rowKeyRight = parts[2] + '_' + parts[3];
        return {
            signaturesLeft: this._getLookupLeft(rowKeyLeft),
            signaturesRight: this._getLookupRight(rowKeyRight)
        }
    }

    getSingleChartPlanet(harmonicPlanet) {
        return this.singleChartRows[harmonicPlanet];
    }

    getSignaturesEitherSide(harmonicPlanet) {
        //aspect.rowKey = aspect.sourceCard + '_' + aspect.sourcePlanet + '_' + aspect.matchCard + '_' + aspect.matchPlanet;                

        return {
            signaturesLeft: this._getLookupLeft(harmonicPlanet),
            signaturesRight: this._getLookupRight(harmonicPlanet)
        }
    }
}


export default {
    compositeLookup: {},
    maxLoadedAspectCount: 10,
    loadedAspects: [],
    init: function (utilService, signatureService, signatureGroupingService, signatureResultService) {
        this.utilService = utilService;
        this.signatureResultService = signatureResultService;
        this.signatureService = signatureService;
        this.signatureGroupingService = signatureGroupingService;
    },
    _addAspectCharts: function (chartX, chartY) {
        var isNew = this.compositeLookup[chartX + '_' + chartY] == null;
        if (isNew) {
            this.compositeLookup[chartX + '_' + chartX] = new CompositeLookup();
            this.compositeLookup[chartX + '_' + chartY] = new CompositeLookup();
            this.compositeLookup[chartY + '_' + chartX] = new CompositeLookup();
            this.compositeLookup[chartY + '_' + chartY] = new CompositeLookup();
            var item = {
                chartX: chartX,
                chartY: chartY
            };
            if (this.loadedAspects.indexOf(item) < 0) {
                this.loadedAspects.push(item);
            }
            if (this.loadedAspects.length > this.maxLoadedAspectCount) {
                var firstItem = this.loadedAspects.shift();
                console.log('removing first item', firstItem);
                if (!this.loadedAspects.find(x => x.chartX == firstItem.chartX)) {
                    delete this.compositeLookup[firstItem.chartX + '_' + firstItem.chartX];
                }
                if (!this.loadedAspects.find(x => x.chartY == firstItem.chartY)) {
                    delete this.compositeLookup[firstItem.chartY + '_' + firstItem.chartY];
                }
                delete this.compositeLookup[firstItem.chartX + '_' + firstItem.chartY];
                delete this.compositeLookup[firstItem.chartY + '_' + firstItem.chartX];
            }
        }
        return isNew;
    },
    _removeAspectCharts: function (chartX, chartY) {
        delete this.compositeLookup[chartX + '_' + chartX];
        delete this.compositeLookup[chartX + '_' + chartY];
        delete this.compositeLookup[chartY + '_' + chartX];
        delete this.compositeLookup[chartY + '_' + chartY];
    },
    clear: function (chartX, chartY) {
        //console.log('clear', chartX, chartY);
        this.compositeLookup[chartX + '_' + chartY].clear();
    },
    getSignatures: function (chartX, chartY, rowKey) {
        return this.compositeLookup[chartX + '_' + chartY].getSignatures(rowKey);
    },
    getSignaturesEitherSide: function (chartX, chartY, harmonicPlanet) {
        return this.compositeLookup[chartX + '_' + chartY].getSignaturesEitherSide(harmonicPlanet);
    },
    addSignature: function (chartX, chartY, signature, firstRows) {
        this.compositeLookup[chartX + '_' + chartY].addSignature(signature, firstRows, chartX === chartY);
        //var shortSignatureType = SignatureTypeEnumValues[signature.signatureType];
        //console.log('degreeLookup for ' + signature.signatureType + ' ' + chartX + '_' + chartY, this.compositeLookup[chartX + '_' + chartY].getSignaturesInDegreeRange(signature.signatureType, 90, 3));
    },
    getSignaturesInDegreeRange: function (chartX, chartY, signatureType, degree, range) {
        return this.compositeLookup[chartX + '_' + chartY].getSignaturesInDegreeRange(signatureType, degree, range);
    },

    hasGrandStar: function (chartX, chartY) {
        return this.compositeLookup[chartX + '_' + chartY].grandStarsPresent;
    },
    getSingleChartPlanet: function (chartY, harmonicPlanet) {
        return this.compositeLookup[chartY + '_' + chartY].getSingleChartPlanet(harmonicPlanet);
    },
    getSignatureMap: function (chartX, chartY) {
        return this.compositeLookup[chartX + '_' + chartY].signatures;
    },
    getOriginalRowKeys: function (chartX, chartY, rowKey) {
        return this.compositeLookup[chartX + '_' + chartY].getOriginalRowKeys(rowKey);
    },
    connectSignatureRows: function (chartX, chartY, signatureType, signatureRows, connectedSignatures) {
        var rightChartExternalRowKeys = null;
        var sourceRow = signatureRows[0];
        var mainRowKey = sourceRow.rowKey;
        var i = 0;
        var rightCounter = 0;
        signatureRows.forEach(row => {
            //var left = row.isX || row.aspect == HarmonicsEnum.CJ;
            var left = i === 0;
            var signatureRowKey = '';
            var rowKey = sourceRow.cardEnum + '_' + sourceRow.planetEnum + '_' + row.cardEnum + '_' + row.planetEnum;
            if (i == 0) {
                signatureRowKey = mainRowKey;
            } else {
                signatureRowKey = mainRowKey + this.utilService.shrinkRowKey(row.cardEnum + '_' + row.planetEnum + (row.isX ? (Constants.X_POSTFIX) : ''));
            }

            i++;
            var sigs = null;
            var sigs2 = null;
            var parts = rowKey.split('_');
            if (left) {
                var rowKeyLeft = parts[0] + '_' + parts[1];
                //same chart, signaturesLeft for planets in left column, signaturesRight for planets in right column
                var dictX = this.getSignaturesEitherSide(chartX, chartX, rowKeyLeft);
                if (dictX && dictX.signaturesLeft && dictX.signaturesLeft[signatureType]) {
                    connectedSignatures[signatureRowKey] = {hasLeft: true};
                    sigs = dictX.signaturesLeft[signatureType];
                    //connectedSignatures[signatureRowKey].rowKeys = dictX.signaturesLeft[signatureType];                    
                } else if (dictX && dictX.signaturesRight && dictX.signaturesRight[signatureType]) {
                    connectedSignatures[signatureRowKey] = {hasLeft: true};
                    sigs2 = dictX.signaturesRight[signatureType];
                }
                if (!sigs) {
                    sigs = sigs2;
                } else if (sigs && sigs2) {
                    sigs2.forEach(item => {
                        if (sigs.indexOf(item) < 0) {
                            sigs.push(item);
                        } else {
                            //                          console.log(' left duplicate sig', item, sigs2.length); //could be multiple orgRowKeys
                        }
                    });
                }
                if (sigs) {
                    if (chartX == chartY) {
                        sigs = sigs.filter(rowKey => {
                            return rowKey != mainRowKey;
                        });
                    }
                    connectedSignatures[signatureRowKey] = {hasLeft: sigs.length > 0};
                    connectedSignatures[signatureRowKey].rowKeys = sigs;
                }

            } else {

                var rowKeyRight = parts[2] + '_' + parts[3];
                var dictY = this.getSignaturesEitherSide(chartY, chartY, rowKeyRight);
                if (dictY && dictY.signaturesRight && dictY.signaturesRight[signatureType]) {
                    sigs = dictY.signaturesRight[signatureType];
                }
                if (dictY && dictY.signaturesLeft && dictY.signaturesLeft[signatureType]) {
                    sigs2 = dictY.signaturesLeft[signatureType];
                }
                if (!sigs) {
                    sigs = sigs2;
                } else if (sigs && sigs2) {
                    sigs2.forEach(item => {
                        if (sigs.indexOf(item) < 0) {
                            sigs.push(item);
                        } else {
//                            console.log('duplicate sig', item, sigs2.length, rowKeyRight);//could be multiple orgRowKeys
                        }
                    });
                }
                if (sigs) {
                    if (!rightChartExternalRowKeys) {
                        rightChartExternalRowKeys = sigs;
                    } else {// thin it out                                                
                        rightChartExternalRowKeys = rightChartExternalRowKeys.filter(
                                element => sigs.includes(element)
                        );
                    }
                    if (chartX == chartY) {
                        sigs = sigs.filter(rowKey => {
                            return rowKey != mainRowKey;
                        });
                        //                console.log('sigs', sigs, mainRowKey);
                    }
                    connectedSignatures[signatureRowKey] = {hasRight: sigs.length > 0};
                    connectedSignatures[signatureRowKey].rowKeys = sigs;
                    rightCounter++;
                }
            }
            //console.log('rightCounter', rightCounter, rightChartExternalRowKeys);
        });
        if (chartX != chartY
                && rightCounter == i - 1
                && rightChartExternalRowKeys
                && rightChartExternalRowKeys.length > 0) {
            var externalRowKey = rightChartExternalRowKeys[0];
            //console.log('externalRowKey',externalRowKey);
            signatureRows.slice(1).forEach(row => {
                if (!row.isX) {
                    //      console.log(row.cardEnum + '_' + row.planetEnum);
                    externalRowKey = externalRowKey.replace(row.cardEnum + '_' + row.planetEnum, '');
                }
            });
            externalRowKey = externalRowKey.replace(_rxAspects, "");
            var parts2 = externalRowKey.match(_rxHarmonicPlanet);
            var harmonicPlanet = (parts2 && parts2.length == 1) ? parts2[0] : null;
            if (harmonicPlanet) {
                signatureRows[0].rightChartSourceHarmonicPlanet = this.getSingleChartPlanet(chartY, harmonicPlanet);
            }
        }
        return connectedSignatures;
    },
    getSignatureInfo: function (chartX, chartY, aspectRowKey, left) {
        var result = {
            cssClass: '',
            rowKeys: [],
            starRowKeys: []
        };
        var signatureType = SignatureTypeEnum.Quintile;
        var dicSigs = this.getSignatures(chartX, chartY, aspectRowKey);
        var dict = left ? dicSigs.signaturesLeft : dicSigs.signaturesRight;
        if (dict && dict[signatureType]) {
            result.cssClass = Constants.cssClassPlanetStar;
            result.starRowKeys = dict[signatureType];
        }
        var parts = aspectRowKey.split('_');
        if (chartX != chartY) {
            if (left) {
                var rowKeyLeft = parts[0] + '_' + parts[1];
                var dictX = this.getSignaturesEitherSide(chartX, chartX, rowKeyLeft);
                if (dictX && dictX.signaturesLeft && dictX.signaturesLeft[signatureType]) {
                    result.cssClass += ' ' + Constants.cssClassLeftSignature;
                    dictX.signaturesLeft[signatureType].forEach(rowKey => {
                        result.rowKeys.push(rowKey);
                    });
                } else if (dictX && dictX.signaturesRight && dictX.signaturesRight[signatureType]) {
                    if (result.cssClass.indexOf(Constants.cssClassLeftSignature) < 0) {
                        result.cssClass += ' ' + Constants.cssClassLeftSignature;
                    }
                    dictX.signaturesRight[signatureType].forEach(rowKey => {
                        result.rowKeys.push(rowKey);
                    });
                }
            }
            if (!left) {

                var rowKeyRight = parts[2] + '_' + parts[3];
                var dictY = this.getSignaturesEitherSide(chartY, chartY, rowKeyRight);
                if (dictY && dictY.signaturesRight && dictY.signaturesRight[signatureType]) {
                    result.cssClass += ' ' + Constants.cssClassRightSignature;
                    dictY.signaturesRight[signatureType].forEach(rowKey => {
                        result.rowKeys.push(rowKey);
                    });
                } else if (dictY && dictY.signaturesLeft && dictY.signaturesLeft[signatureType]) {
                    if (result.cssClass.indexOf(Constants.cssClassRightSignature) < 0) {
                        result.cssClass += ' ' + Constants.cssClassRightSignature;
                    }
                    dictY.signaturesLeft[signatureType].forEach(rowKey => {
                        result.rowKeys.push(rowKey);
                    });
                }
            }
        }
        return result;
    },
    addSignatures: function (chartX, chartY, signatures, aspectTemplateId, signatureTarget) {
        var promises = [];
        var flexChartX = this.signatureService.getChartX(signatureTarget, chartX, chartY);
        var flexChartY = this.signatureService.getChartY(signatureTarget, chartX, chartY);
        this.clear(flexChartX, flexChartY);
        signatures.forEach(signature => {
            var storageKey = this.signatureResultService.getSignatureResultStorageKey(chartX, chartY, signature.id, aspectTemplateId, signatureTarget);
            promises.push(this.signatureService.getSignatureResultFromStorage(signature, storageKey, aspectTemplateId).then(rows => {
                //console.log('addSignature', storageKey);
                var signatureResultRows = rows ? this.signatureGroupingService.process(rows, signature.signatureType) : null;
                if (signatureResultRows) {
                    this.addSignature(flexChartX, flexChartY, signature, signatureResultRows);
                }
            }));
        });
        return Promise.all(promises);
    },
    loadAspect: function (aspect, aspectTemplate) {
        var isNew = this._addAspectCharts(aspect.chartX, aspect.chartY);
        var promises = [];
        if (isNew) {
            try {
                var promise = this.signatureResultService.ensureAllSignatures(aspect, aspectTemplate).then(() => {
                    var promises2 = [];
                    Object.keys(SignatureTargetEnum).forEach(signatureTarget => {
                        var signatures = this.signatureResultService.getSignatures(signatureTarget, aspectTemplate);
                        promises2.push(
                                this.addSignatures(
                                        aspect.chartX,
                                        aspect.chartY,
                                        signatures,
                                        aspectTemplate.id,
                                        signatureTarget));
                    });
                    return Promise.all(promises2);
                });
                promises.push(promise);
            } catch (e) {
                this._removeAspectCharts(aspect.chartX, aspect.chartY);
                throw e;
            }
        }
        return Promise.all(promises);
    },
}