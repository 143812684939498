import moment from 'moment';
import {HarmonicsEnum} from '@/constants/enums';
import {AllKeysLookup, AllKeysLookupReverse} from '@/constants/enums';
import {DegreeVM} from '@/models/degreeVM';
import Constants from '@/constants/constants';
const _rxCardPlanet = /(?<xprefix>XX)?(?<card>H[^_]+)_(?<planet>[A-Z]{2})(_?(?<aspect>[-A-Z]{2}\d*)_?)?_?/g; //[-A-Z] to also get XX CJ10 is also possible

export default  {

    toggleDarkTheme: function (shouldAdd) {
        document.body.classList.toggle('dark', shouldAdd);
    },
    padStartString: function (s, targetLength, padString) {
        targetLength = targetLength >> 0; //truncate if number or convert non-number to 0;
        padString = String((typeof padString !== 'undefined' ? padString : ' '));
        if (s.length > targetLength) {
            return String(s);
        } else {
            targetLength = targetLength - s.length;
            if (targetLength > padString.length) {
                padString += padString.repeat(targetLength / padString.length); //append to original to ensure we are longer than needed
            }
            return padString.slice(0, targetLength) + String(s);
        }
    },
    _showMissingKeyMessage: function (key) {
        var message = 'IMPORTANT: A MAPPING FOR ' + key + ' HAS TO BE ADDED TO AllKeysLookup';
        console.log(message);
        throw message;
    },
    shrinkRowKey: function (rowKey) {
        var result = '';
        if (rowKey && (rowKey.startsWith('H') || rowKey.startsWith(Constants.X_PREFIX))) {
            var parts = rowKey.split('_');
            var check = '';
            parts.forEach(part => {
                if (part.startsWith(Constants.X_PREFIX)) {
                    result += AllKeysLookup[Constants.X_PREFIX];
                    check = AllKeysLookup[part.substr(2)];
                    if (!check) {
                        this._showMissingKeyMessage(part.substr(2));
                    }
                    result += check;
                } else if (part.endsWith(Constants.X_POSTFIX)) {
                    check = AllKeysLookup[part.substr(0, 2)];
                    if (!check) {
                        this._showMissingKeyMessage(part.substr(0, 2));
                    }
                    result += check;
                    result += AllKeysLookup[Constants.X_POSTFIX];
                } else if (part.startsWith('CJ')) {
                    check = AllKeysLookup['CJ'];
                    if (!check) {
                        this._showMissingKeyMessage('CJ');
                    }
                    result += check;
                } else {
                    check = AllKeysLookup[part];
                    if (!check) {
                        this._showMissingKeyMessage(part);
                    }
                    result += check;
                }
            });
        } else {
            result = rowKey;
        }
        //var check = this.expandRowKey(result);
        //console.log(rowKey == check, result, rowKey, check);
        return result;
    },
    expandRowKeys: function (rowKeys) {
        return rowKeys ? rowKeys.map(rowKey => {
            return this.expandRowKey(rowKey);
        }) : [];
    },
    expandRowKey: function (rowKey) {
        var result = '';
        if (!rowKey || rowKey.startsWith('H') || rowKey.startsWith(Constants.X_PREFIX)) {
            result = rowKey;
        } else {
            var ar = Array.from(rowKey);
            var length = rowKey.length;
            var CJCount = 0;
            var prevPart = '';
            for (var i = 0; i < length; i++) {
                var part = AllKeysLookupReverse[ar[i]];
                if (part == 'CJ') {
                    part += (++CJCount).toString();
                }
                if (i > 0 && prevPart != Constants.X_PREFIX && part != Constants.X_POSTFIX) {
                    result += '_';
                }
                result += part;

                prevPart = part;
            }
        }
        return result;
    },
    parseRowKey: function (rowKey) {
        const array = [...rowKey.matchAll(_rxCardPlanet)];
        var cards = [];
        var planets = [];
        var aspects = [];
        var isLeft = [];
        array.forEach(match => {
            var groups = match.groups;
            cards.push(groups.card);
            planets.push(groups.planet);
            if (groups.aspect) {
                aspects.push(groups.aspect);
            }
            isLeft.push(groups.xprefix ? true : false);
        });
        return {
            isLeft: isLeft,
            cards: cards,
            planets: planets,
            aspects: aspects
        };
    },
    cleanupLicenseKey: function (licenseKey) {
//insert dash at 8-4-4-4-12
        licenseKey = licenseKey.replace(/-/g, '');
        var indexes = new Array(8, 12, 16, 20, 32);
        var prev = 0;
        var result = '';
        indexes.forEach(function (val) {
            if (result.length > 0) {
                result += '-';
            }
            if (licenseKey.length >= val) {
                result += licenseKey.substring(prev, val);
            } else if (licenseKey.length > prev) {
                result += licenseKey.substring(prev);
            }
            prev = val;
        });
        return result;
    },
    createGuid() {
        var dt = new Date().getTime();
        var result = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            var r = (dt + Math.random() * 16) % 16 | 0;
            dt = Math.floor(dt / 16);
            return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
        });
        return result;
    },
    copySimpleObject: function (row) {
        var result = {};
        for (var key in row) {
            result[key] = row[key];
        }
        return result;
    },
    cloneObject: function (item) {
        return item ? JSON.parse(JSON.stringify(item)) : null;
    },
    isEqualObject: function (a, b) {
        return JSON.stringify(a) == JSON.stringify(b);
    },
    formatString: function (s, args) {
        var result = "";
        if (s) {
            result = s.replace(/{(\d+)}/g, function (match, number) {
                return typeof args[number] != 'undefined'
                        ? args[number]
                        : match
                        ;
            });
        }
        return result;
    },
    truncateString: function (value, maxLength) {
        var hasSpace = value.indexOf(' ') > 0;
        if (value.length > maxLength && !hasSpace) {
            value = value.substr(0, maxLength) + '...';
        } else if (hasSpace) {
            var parts = value.split(' ');
            var total = '';
            parts.forEach(function (part) {
                if (total) {
                    total += ' ';
                }
                if (total.length + part.length < maxLength * 2) {
                    total += part;
                } else {
                    total += '...';
                    return false;
                }
            });
            value = total;
        }
        return value;
    },
    formatISODate: function (date) {
        return this.formatDate(date, 'YYYY/MM/DD HH:mm:ss:SSS');
    },
    formatDate: function (value, format) {
        format = format ? format : 'MM/DD/YYYY HH:mm:ss:SSS';
        return (value) ? moment(value).format(format).replace(':000', '') : value;
    },
    getDateFromDateStruct: function (dateStruct)/*: Date */ {
        //  console.log('getDate', time);
        return dateStruct ? new Date(dateStruct.year, dateStruct.month, dateStruct.day, dateStruct.hour, dateStruct.minute, dateStruct.second) : null;
    },
    getObjectHash: function (item)/*: number*/ {
        return this.getHash(JSON.stringify(item));
    },
    getHash: function (s) {

        var hash = 0, i, chr;
        if (s.length === 0)
            return hash;
        for (i = 0; i < s.length; i++) {
            chr = s.charCodeAt(i);
            hash = ((hash << 5) - hash) + chr;
            hash |= 0; // Convert to 32bit integer
        }
        return hash;
    },

    isValidString: function (value) {
        return !(!value);
    },

    isValidNumber: function (value) {
        return value != null && value !== "" && !isNaN(value);
    },

    isValidEmail: function (value) {
        var rxEmail = /([\w!#$%&'*+/=?^_`{|}~-]+(\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(\w(\w*\w)?\.)+\w([\w-]*\w)?)/;
        return rxEmail.test(value);
    },
    parseNullableInt: function (value) {
        return value == null || value == "" || isNaN(value) ? null : parseInt(value);
    },
    getDistinctArray: function (array) {
        return [...new Set(array)];
    },
    sortDictionaryOnKeys: function (dict, desc) {
        var sorted = [];
        var result = {};
        for (var key in dict) {
            sorted[sorted.length] = key;
        }
        sorted.sort((a, b) => {
            return desc ? (a > b ? -1 : 1) : (a > b ? 1 : -1);
        });
        for (var i = 0; i < sorted.length; i++) {
            result[sorted[i]] = dict[sorted[i]];
        }
        return result;
    },
    sortDictionaryOnStringValueField: function (dict, valueField, desc, extraDict, extraDictField) {
        var sortedValues = [];
        var result = {};
        for (var key in dict) {
            var sortValue = dict[key][valueField];
            if (extraDict && extraDictField) {
                var item = extraDict[sortValue];
                sortValue = item ? item[extraDictField] : '';
            }
            sortedValues[sortedValues.length] = {key: key, value: sortValue};
        }
        sortedValues.sort((a, b) => {
            return desc ? (a.value.toLowerCase() > b.value.toLowerCase() ? -1 : 1) : (a.value.toLowerCase() > b.value.toLowerCase() ? 1 : -1);
        });
        //console.log('sortedValues', sortedValues);

        for (var i = 0; i < sortedValues.length; i++) {
            result[sortedValues[i].key] = dict[sortedValues[i].key];
        }
        return result;
    },
    removeDash: function (licenseKey) {
        return licenseKey ? licenseKey.replace(/-/g, '') : '';
    },

    getSign: function (planetLocation) {
        var result = '';
        if (planetLocation) {
            result = this.padStartString(planetLocation.degree.toString(), 2, '0') + ' ' + planetLocation.sign + ' ' + this.padStartString(planetLocation.minute.toString(), 2, '0');
        }
        return result;
    },

    getDoubleFromDegree: function (outerLimitDegree) {
        var degree = outerLimitDegree;
        var parts = outerLimitDegree.toString().split('.');
        if (parts.length == 2) {
            outerLimitDegree = parts[0];
            var outerLimitMinute = parts[1];
            degree = parseInt(outerLimitDegree);
            var minute = parseInt(outerLimitMinute);
            minute /= 60.0;
            degree += minute;
        }
        return degree;
    },

    getDoubleFromDegreeMinute: function (outerLimitDegree, outerLimitMinute) {
        var degree = 0.0;
        if (outerLimitDegree !== null
                && outerLimitDegree !== ''
                && outerLimitMinute !== null
                && outerLimitMinute !== '') {
            degree = parseInt(outerLimitDegree);
            var minute = parseInt(outerLimitMinute);
            minute /= 60.0;
            degree += minute;
        }
        return degree;
    },

    getDegreeVM: function (value) {
        var result = new DegreeVM();
        var degree = Math.floor(value);
        value -= degree;
        value *= 60;
        var minute = Math.floor(value);
        value -= minute;
        value *= 60;
        var second = Math.floor(value);
        value -= second;
        value *= 100;
        var centSecond = Math.floor(value);
        if (centSecond >= 50) {
            second++;
        }
        result.degree = degree;
        result.minute = this.padStartString(minute, 2, '0');
        result.second = this.padStartString(second, 2, '0');
        result.totalSeconds = second + (minute * 60) + (degree * 60 * 60);
        return result;
    },

    getHarmonic: function (diff) {
        var result = null;
        if (diff < 0) {
            diff += 360.0;
        }
        var distance = null;
        if (this.isWithinLimit(diff, 0.0)) {
            result = HarmonicsEnum.CJ;
            distance = Math.abs(diff - 0.0);
        } else if (this.isWithinLimit(diff, 120.0)) {
            result = HarmonicsEnum.TR;
            distance = Math.abs(diff - 120.0);
        } else if (this.isWithinLimit(diff, 240.0)) {
            result = HarmonicsEnum.TR;
            distance = Math.abs(diff - 240.0);
        } else if (this.isWithinLimit(diff, 90.0)) {
            result = HarmonicsEnum.SQ;
            distance = Math.abs(diff - 90.0);
        } else if (this.isWithinLimit(diff, 270.0)) {
            result = HarmonicsEnum.SQ;
            distance = Math.abs(diff - 270.0);
        } else if (this.isWithinLimit(diff, 180.0)) {
            result = HarmonicsEnum.OP;
            distance = Math.abs(diff - 180.0);
        } else if (this.isWithinLimit(diff, 150.0)) {
            result = HarmonicsEnum.QX;
            distance = Math.abs(diff - 150.0);
        } else if (this.isWithinLimit(diff, 210.0)) {
            result = HarmonicsEnum.QX;
            distance = Math.abs(diff - 210.0);
        } else if (this.isWithinLimit(diff, 300.0)) {
            result = HarmonicsEnum.SX;
            distance = Math.abs(diff - 300.0);
        } else if (this.isWithinLimit(diff, 60.0)) {
            result = HarmonicsEnum.SX;
            distance = Math.abs(diff - 60.0);
        }

        return {
            harmonic: result,
            distance: distance
        };
    },
    isWithinLimit: function (diff, distance) {
        return Math.abs(diff - distance) < Constants.outerLimit;
    }
}