import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { defineComponent } from 'vue';
import SideMenu from '@/components/side-menu'
//import SideMenu from '@/side-menu'
export default defineComponent({
    name: 'App',
    components: {
        IonApp,
        IonRouterOutlet,
        SideMenu
    }
});

