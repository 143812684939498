import {GridRowColEnum, HarmonicsEnum, QuadrantEnum, PlanetEnum} from '@/constants/enums';
import {SignatureTargetEnum} from '@/constants/enums';
import Constants from '@/constants/constants';
import utilService from '@/services/utilService';
export class AspectPropertiesVM {

    constructor(aspectPropertyVm) {
        this.rootKey = 'origins';
        this[this.rootKey] = {};
        if (!aspectPropertyVm || !Object.hasOwn(aspectPropertyVm, this.rootKey)) {
            var obj = {};
            obj[this.rootKey] = aspectPropertyVm ? utilService.cloneObject(aspectPropertyVm) : {};
            aspectPropertyVm = obj;
        }
        this.init(aspectPropertyVm, true);
    }
    clear() {
        this.init(null, false);
    }
    init(aspectPropertyVm, hardOverwrite) {
//        console.log('init', aspectPropertyVm);
        this._setProperty(aspectPropertyVm, 'selectedCardButtons', []);
        this._setProperty(aspectPropertyVm, 'selectedHarmonicButtons', [HarmonicsEnum.CJ, HarmonicsEnum.OP, HarmonicsEnum.TR, HarmonicsEnum.SQ, HarmonicsEnum.QX]);
        var defaultPlanets = [];
        for (var key in PlanetEnum) {
            defaultPlanets.push(key);
        }

        this._setProperty(aspectPropertyVm, 'selectedPlanetButtons', defaultPlanets);
        this._setProperty(aspectPropertyVm, 'selectedQuadrantButtons', [QuadrantEnum.Q1, QuadrantEnum.Q2, QuadrantEnum.Q3, QuadrantEnum.Q4]);
        this._setProperty(aspectPropertyVm, 'outerLimit', null);

        this._setProperty(aspectPropertyVm, 'gridSortCol', GridRowColEnum.NormalizedOrb);
        this._setProperty(aspectPropertyVm, 'gridSortAsc', true);
        this._setProperty(aspectPropertyVm, 'gridScroll', true);
        this._setProperty(aspectPropertyVm, 'gridExtraColumns', true);
        this._setProperty(aspectPropertyVm, 'gridShowHiddenRows', false);
        this._setProperty(aspectPropertyVm, 'gridHighlightsToTop', false);
        this._setProperty(aspectPropertyVm, 'filterH108Aspects', false);
        
        this._setProperty(aspectPropertyVm, 'hiddenAspects', []);
        this._setProperty(aspectPropertyVm, "gridPageSize", Constants.defaultPageSize);

        this._setProperty(aspectPropertyVm, 'chartOneSelectedPlanets', []);

        this._setProperty(aspectPropertyVm, 'aspectTemplateForOrigins', null);

        this._setProperty(aspectPropertyVm, 'signatureTargetEnum', SignatureTargetEnum.ChartX_ChartY);
        this._setProperty(aspectPropertyVm, 'grid2SortCol', GridRowColEnum.NormalizedOrb);
        this._setProperty(aspectPropertyVm, 'grid2SortAsc', true);
        this._setProperty(aspectPropertyVm, 'grid2Scroll', true);
        this._setProperty(aspectPropertyVm, 'grid2ExtraColumns', true);
        this._setProperty(aspectPropertyVm, 'grid2ShowHiddenRows', false);
        this._setProperty(aspectPropertyVm, 'grid2HighlightsToTop', false);
        this._setProperty(aspectPropertyVm, 'grid2HiddenAspects', []);
        this._setProperty(aspectPropertyVm, 'grid2ChartOneSelectedPlanets', []);


        this._setProperty(aspectPropertyVm, 'grid3SortCol', GridRowColEnum.NormalizedOrb);
        this._setProperty(aspectPropertyVm, 'grid3SortAsc', true);
        this._setProperty(aspectPropertyVm, 'grid3Scroll', true);
        this._setProperty(aspectPropertyVm, 'grid3ExtraColumns', true);
        this._setProperty(aspectPropertyVm, 'grid3ShowHiddenRows', false);
        this._setProperty(aspectPropertyVm, 'grid3HighlightsToTop', false);
        this._setProperty(aspectPropertyVm, 'grid3HiddenAspects', []);
        this._setProperty(aspectPropertyVm, 'grid3ChartOneSelectedPlanets', []);

        this._setProperty(aspectPropertyVm, 'grid4SortCol', GridRowColEnum.NormalizedOrb);
        this._setProperty(aspectPropertyVm, 'grid4SortAsc', true);
        this._setProperty(aspectPropertyVm, 'grid4Scroll', true);
        this._setProperty(aspectPropertyVm, 'grid4ExtraColumns', true);
        this._setProperty(aspectPropertyVm, 'grid4ShowHiddenRows', false);
        this._setProperty(aspectPropertyVm, 'grid4HighlightsToTop', false);

        this._setProperty(aspectPropertyVm, 'grid4HiddenAspects', []);


        this._setProperty(aspectPropertyVm, 'grid4ChartOneSelectedPlanets', []);

        this._setProperty(aspectPropertyVm, 'gridImportHighlights', false);


        this._setProperty(aspectPropertyVm, 'grid1SignaturesHighlightsToTop', {});
        this._setProperty(aspectPropertyVm, 'grid2SignaturesHighlightsToTop', {});
        this._setProperty(aspectPropertyVm, 'grid3SignaturesHighlightsToTop', {});
        this._setProperty(aspectPropertyVm, 'grid4SignaturesHighlightsToTop', {});
        this._setProperty(aspectPropertyVm, 'showGrandStars', {ChartX_ChartX: false, ChartX_ChartY: false, ChartY_ChartX: false, ChartY_ChartY: false});

        this._setProperty(aspectPropertyVm, 'useDefaultSignatureLimits', true);
        this._setProperty(aspectPropertyVm, 'adjustedOuterLimits', {});
        this._setProperty(aspectPropertyVm, "sortConnectingGrandStars", {});

        if (hardOverwrite) {

            this._setProperty(aspectPropertyVm, 'highlightAspects', {});
            this._setProperty(aspectPropertyVm, 'grid2HighlightAspects', {});
            this._setProperty(aspectPropertyVm, 'grid3HighlightAspects', {});
            this._setProperty(aspectPropertyVm, 'grid4HighlightAspects', {});

            this._setProperty(aspectPropertyVm, 'grid1HighlightSignatures', {});
            this._setProperty(aspectPropertyVm, 'grid2HighlightSignatures', {});
            this._setProperty(aspectPropertyVm, 'grid3HighlightSignatures', {});
            this._setProperty(aspectPropertyVm, 'grid4HighlightSignatures', {});

            this._setProperty(aspectPropertyVm, 'grid1SelectedSignature', null);
            this._setProperty(aspectPropertyVm, 'grid2SelectedSignature', null);
            this._setProperty(aspectPropertyVm, 'grid3SelectedSignature', null);
            this._setProperty(aspectPropertyVm, 'grid4SelectedSignature', null);

            this._setProperty(aspectPropertyVm, 'grid1AspectNotes', {});
            this._setProperty(aspectPropertyVm, 'grid2AspectNotes', {});
            this._setProperty(aspectPropertyVm, 'grid3AspectNotes', {});
            this._setProperty(aspectPropertyVm, 'grid4AspectNotes', {});

            this._setProperty(aspectPropertyVm, 'grid1SignatureNotes', {});
            this._setProperty(aspectPropertyVm, 'grid2SignatureNotes', {});
            this._setProperty(aspectPropertyVm, 'grid3SignatureNotes', {});
            this._setProperty(aspectPropertyVm, 'grid4SignatureNotes', {});

            this._setProperty(aspectPropertyVm, "selectedGrandStarsV2", {});
            this._setProperty(aspectPropertyVm, "benMals", {});
        }
    }
    _setProperty(aspectPropertyVm, key, defaultValue) {
        if (aspectPropertyVm && aspectPropertyVm[this.rootKey] && aspectPropertyVm[this.rootKey][key] != null) {
            if (Array.isArray(defaultValue) && aspectPropertyVm[this.rootKey][key].length == 0) {
                this[this.rootKey][key] = defaultValue;
            } else {
                var value = aspectPropertyVm[this.rootKey][key];
                if ((key == 'highlightAspects'
                        || key == 'grid2HighlightAspects'
                        || key == 'grid3HighlightAspects'
                        || key == 'grid4HighlightAspects'
                        )
                        && Array.isArray(value)) {
                    //refactor it to be a dictionary
                    var newVal = {};
                    value.forEach(val => {
                        newVal[val] = 1;
                    });
                    value = newVal;
                } else if (key == "grid1HighlightSignatures"
                        || key == "grid2HighlightSignatures"
                        || key == "grid3HighlightSignatures"
                        || key == "grid4HighlightSignatures"
                        ) {
                    for (var itemKey in value) {
                        if (!Array.isArray(value[itemKey])) {
                            break;
                        }
                        var newItem = {};
                        value[itemKey].forEach(val => {
                            newItem[val] = 1;
                        });
                        value[itemKey] = newItem;
                    }
                }
                if (defaultValue != null && typeof value != typeof defaultValue && defaultValue) {
                    //programmer's error, fix it
                    value = defaultValue;
                }
                this[this.rootKey][key] = value != null ? value : defaultValue;
            }
        } else {
            this[this.rootKey][key] = defaultValue;
        }
    }

}
