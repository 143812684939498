import Constants from '@/constants/constants';
import {SignatureTypeEnum, SignatureTargetEnum} from '@/constants/enums';

export default{
    init: function (utilService, signatureService, signatureGroupingService) {
        this.utilService = utilService;
        this.signatureService = signatureService;
        this.signatureGroupingService = signatureGroupingService;
    },

    getSignatures: function (signatureTargetEnum, aspectTemplate) {
        var result = aspectTemplate.signatures.filter(x => {
            return x.target == signatureTargetEnum;
        });
        return result;
    },

    getSignatureResultStorageKey: function (chartX, chartY, signatureId, aspectTemplateId, signatureTargetEnum) {
        return Constants.signatureResultPrefix + '_' + chartX + '_' + chartY + '_' + signatureId + '_' + aspectTemplateId + '_' + signatureTargetEnum;
    },

    loadSignatureResults: function (signatures, chartX, chartY, aspectTemplateId, signatureTarget) {
        var promises = [];
        var flexChartX = this.signatureService.getChartX(signatureTarget, chartX, chartY);
        var flexChartY = this.signatureService.getChartY(signatureTarget, chartX, chartY);
        signatures.forEach(signature => {
            if (signature.signatureType != SignatureTypeEnum.SealOfSolomon
                    && signature.signatureType != SignatureTypeEnum.SquaringGrandCross
                    )
            {
                var storageKey = this.getSignatureResultStorageKey(chartX, chartY, signature.id, aspectTemplateId, signatureTarget);
                promises.push(this.signatureService.loadSignatureResult(signature,
                        aspectTemplateId,
                        flexChartX,
                        flexChartY,
                        storageKey
                        ));
            }
        });
        return Promise.all(promises).then(() => {
            console.log('loadSignatureResults done', signatureTarget);
        });
    },

    ensureAllSignatures: function (aspect, aspectTemplate) {
        var promises = [];
        Object.keys(SignatureTargetEnum).forEach(signatureTarget => {
            var signatures = this.getSignatures(signatureTarget, aspectTemplate);
            promises.push(this.loadSignatureResults(signatures,
                    aspect.chartX,
                    aspect.chartY,
                    aspectTemplate.id,
                    signatureTarget
                    ));
        });
        return Promise.all(promises).then(() => {
            console.log('ensureAllSignatures done');
        });
    },

    getSignatureResultRows: function (chartX, chartY, signature, aspectTemplateId, signatureTarget, rowKeys) {
        //we assume the signatureResults are loaded already through loadSignatureResult
        rowKeys = rowKeys.map(x => {
            return this.utilService.shrinkRowKey(x);
        });
        var storageKey = this.getSignatureResultStorageKey(chartX, chartY, signature.id, aspectTemplateId, signatureTarget);
//        console.log('getSignatureResultRows', storageKey, aspectTemplateId, rowKeys, signature.id);
        return this.signatureService.getSignatureResultFromStorage(signature, storageKey, aspectTemplateId).then(result => {

            result = this.signatureGroupingService.process(result, signature.signatureType);

//            console.log('is there a match 2?', rowKeys, result.filter(signatureRows => {
//                return signatureRows[0].originalRowKeys && signatureRows[0].originalRowKeys.find(rowKey => {
//                    return rowKeys.includes(rowKey);
//                });
//            }));

            return result.filter(signatureRows => {
                return rowKeys.indexOf(signatureRows[0].rowKey) >= 0
                        || (signatureRows[0].originalRowKeys && signatureRows[0].originalRowKeys.find(rowKey => {
                            return rowKeys.includes(rowKey);
                        }));
            });
        });
    }

}
