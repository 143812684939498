
export default {
        
    get selectedLanguage() {
        return "en";//temporary constant
    },
    getConfigSetting(setting) {
        if (!setting.startsWith('VUE_APP_'))
        {
            setting = 'VUE_APP_' + setting;
        }
        return process.env[setting];
    },
    getApiRootUrl() {
        return process.env.VUE_APP_ApiRootUrl;
    },
    
    get apiRootUrl() {
        return this.getApiRootUrl();
    },
    
    get parentDomain(){
        return this.getConfigSetting("ParentDomain");
    }
    
}