export default{
    init: function (httpClientService) {
        this.url = "aspecttemplate/";
        this.httpClientService = httpClientService;
    },
    getAspectTemplates: function () {
        return this.httpClientService.get(this.url + 'list');
    },
    getDefaultAspectTemplateId: function () {
        return this.httpClientService.get(this.url + 'defaultfororigins').then(x => {
            var result = null
            if (x.succeeded) {
                result = x.aspectTemplateId;
            }
            return result;
        });
    },
    getAspectTemplate: function (aspectTemplateId) {
        return this.httpClientService.get(this.url + aspectTemplateId);
    }
}