import { createRouter, createWebHistory } from '@ionic/vue-router';
//import { RouteRecordRaw } from 'vue-router';

const routes = [
//  {
//    path: '/index.html#/origins/:id',
//    component: () => import ('@/origins-page')
//  },
    {
        path: '/origins', component: () => import('@/origins-page')
    }, {
        path: '/grandstars/:aspectId', component: () => import('@/grandstar-page')
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
