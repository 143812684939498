import {SortedLinkedList} from '@/models/sortedLinkedList';

export class DegreeLookupVM {
    constructor(card, planet, signatureRowKey, isX) {
        this.card = card;
        this.planet = planet;
        this.sigKey = signatureRowKey;
        this.isX = isX;
    }
}
/*
 We store degrees of planets in hashtable on (degree,sortedLinkedLists)
 every X degrees we have a sortedLinkedList to group the values in a linkedlist that can be iterated in the order of the degree
 */
export class DegreeLookup {
    constructor() {
        this.degreeSections = {};
        this.degreeStep = 5;
    }
    insert(signatureType, degree, degreeLookupVM) {
        var baseDegree = parseInt(degree / this.degreeStep) * this.degreeStep;
        var sectionKey = signatureType + '_' + baseDegree;
        if (!this.degreeSections[sectionKey]) {
            this.degreeSections[sectionKey] = new SortedLinkedList(true);
        }
        this.degreeSections[sectionKey].insert(degree, degreeLookupVM);
    }

    getRange(signatureType, startDegree, endDegree) {
        var baseDegreeStart = parseInt(startDegree / this.degreeStep) * this.degreeStep;
        var baseDegreeEnd = parseInt(endDegree / this.degreeStep) * this.degreeStep;
        var result = [];
        for (var step = baseDegreeStart; step <= baseDegreeEnd; step += this.degreeStep) {
            var sectionKey = signatureType + '_' + step;
            if (this.degreeSections[sectionKey]) {
                result = result.concat(this.degreeSections[sectionKey].getRange(startDegree, endDegree));
            }
        }
        return result.map(x => {
            return {
                degree: x.key,
                value: x.value
            }
        });
    }
    getAll(signatureType) {
        return this.getRange(signatureType, 0, 360);
    }
}

/*
 var lst = new DegreeLookup();
 
 lst.insert(3, 3);
 lst.insert(4, 4);
 lst.insert(2, 2);
 lst.insert(6, 6);
 lst.insert(4, 45);
 lst.insert(4, 4);
 lst.insert(5, 5);
 lst.insert(10, 10);
 lst.insert(1, 1);
 lst.insert(7, 7);
 lst.insert(2.7, 2.7);
 
 console.log('RANGE', lst.getRange(4, 14));
 
 
 
 var lst = new DegreeLookup();
 
 lst.insert(1, 3, {a: 3, b: 3});
 lst.insert(2, 4, {a: 3, b: 3});
 lst.insert(1, 2, {a: 2, b: 2});
 lst.insert(1, 2, {a: 2, b: 2});
 lst.insert(2, 2, {a: 2, b: 2});
 lst.insert(1, 10, {a: 13, b: 13});
 
 lst.insert(1, 3, {a: 3, b: 3});
 lst.insert(1, 3, {a: 4, b: 3});
 lst.insert(1, 1, {a: 2, b: 2});
 
 console.log('RANGE', lst.getRange(1, 1, 14));
 
 */