import {StorageEnum} from '@/constants/enums';
import Constants from '@/constants/constants';
export default{
    _rxSignatures: new RegExp(Constants.signatureResultPrefix + '_(?<id>-?\\d+)_(?<id2>-?\\d+).+'),
    _rxQuadrants: new RegExp(Constants.quadrantStoragePrefix + '_(?<id>-?\\d+)_(?<id2>-?\\d+).+'),

    init: function (httpClientService, storageService) {
        this.url = "chart/";
        this.httpClientService = httpClientService;
        this.storageService = storageService;
    },
    getChart: function (id, clearCache) {
        console.log('getChart');
        if (clearCache) {
            return this._getChecksumChanged(id).then(changed => {
                if (changed) {
                    return this._getChart(id);
                } else {
                    return this.storageService.get(StorageEnum.Chart + '_' + id);
                }
            });
        } else {
            return this.storageService.get(StorageEnum.Chart + '_' + id).then(chart => {
                if (!chart) {
                    return this._getChart(id);
                }
                return chart;
            });
        }
    },

    _getChart: function (id) {
        return this.httpClientService.get(this.url + id).then(chart => {
            var key = StorageEnum.Chart + '_' + id;
            return this.storageService.set(key, chart).then(() => {
                return chart;
            });
        })
    },
    _clearStorage: function (id) {
        //clear related storage keys here
        //signature_result_X_X 
        return this.storageService.getAllKeys().then(storageKeys => {
            var promises = [];

            storageKeys.forEach(storageKey => {
                var found = storageKey.match(this._rxSignatures);
                if (!found) {
                    found = storageKey.match(this._rxQuadrants);
                }
                if (found && found.groups) {

                    for (var key in found.groups) {
                        var chartId = found.groups[key];
                        if (chartId == id) {
                            console.log('clearing ', storageKey);
                            promises.push(this.storageService.remove(storageKey));
                            break;//not twice in case they're the same
                        }
                    }
                }
            });

            return Promise.all(promises);
        });
    },
    _getChecksumChanged: function (id) {
        return this.httpClientService.get(this.url + "checksum/" + id).then(newChecksum => {
            var key = StorageEnum.ChartChecksum + '_' + id;
            return this.storageService.get(key).then(oldChecksum => {
                var changed = !oldChecksum || oldChecksum != newChecksum;
                if (changed) {
                    console.log('checksum changed', oldChecksum, newChecksum);
                    return this._clearStorage(id).then(() => {
                        return this.storageService.set(key, newChecksum).then(() => {
                            return changed;
                        });
                    });
                }
                return changed;
            });
        });
    },
    getCharts: function () {
        return this.httpClientService.get(this.url + 'list');
    }
}