import {MessageTypeEnum} from '@/constants/enums';
import {Message} from '@/models/message';
////here we can communicate with the parent window for as long as astrobets-ionic needs to run within an iframe
//we can send messages to tell the parentwindow to navigate to a specific location from within this iframe
export default {
    init: function (configService) {
        this.parentDomain = configService.parentDomain;
        console.log('parentDomain', this.parentDomain);

    },
    sendMessage: function (message) {
        window.parent.postMessage(
                message,
                this.parentDomain
                );
    },
    sendInfoToApp: function (info) {
        var message = new Message(info, MessageTypeEnum.InfoForApp);
        this.sendMessage(message);
    },
    navigateParent: function (url) {
        if (!url.startsWith('/')) {
            url = '/' + url;
        }
        var message = new Message(this.parentDomain + url, MessageTypeEnum.NavigateTo);
        this.sendMessage(message);
    },
    reload: function () {
        var message = new Message(this.parentDomain, MessageTypeEnum.Reload);
        this.sendMessage(message);
    }

}

