import { IonMenu, IonContent, IonButtons, IonButton, menuController} from '@ionic/vue';
import {EnumResourceStrings} from '@/constants/enumresourcestrings';
import router from '@/router';

export default {
    components: {
        IonMenu, IonContent, IonButtons, IonButton
    },
    data() {
        return {
            menuId: 'hamburger-menu',
            _style: '',
            labels: {}
        }
    },

    inject: ["languageService", "storageService", "messageService"],
    mounted() {
        this.languageService.getText(EnumResourceStrings.ClearCache).then(x => this.labels.clearCache = x);
        this.languageService.getText(EnumResourceStrings.Settings).then(x => this.labels.settings = x);
    },
    computed: {
        menuItems: function () {
            var result = [];
            var currentPath = this.$route.path;
            console.log('currentPath', currentPath);
            var isOrigins = currentPath.startsWith('/origins');
            var isGrandStars = currentPath.startsWith('/grandstars');
            var showSettings = isGrandStars;
            var showClearCache = isOrigins;

            if (showClearCache) {
                result.push({
                    title: this.labels.clearCache,
                    click: this.clearCache,
                    id: 'clearCache'
                });
            }
            if (showSettings) {
                result.push({
                    title: this.labels.settings,
//                    click: this.showSettings,
                    id: 'editPageSettings'
                });
            }

            this._style = 'max-height:' + (result.length * 50) + 'px;';
            return result;
        },
        style: function () {
            return this._style;
        }

    },
    methods: {
        menuClick: function (item) {
            //var type = item.type;
            console.log(item, item.href);
            if (item.href) {
                router.replace(item.href);
            } else if (item.click) {
                item.click();
            }
            this.close();
        },
        clearCache: function () {
            this.storageService.clear().then(() => {
                this.messageService.reload();
            });
        },
        showSettings: function () {
            //handled through id trigger
        },
        close: function () {
            menuController.close(this.menuId);
        }
    }

}
