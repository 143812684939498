//import {AspectPropertiesVM} from '@/models/aspectPropertiesVM';
import {CardEnum} from '@/constants/enums';
export default{
    init: function (httpClientService, storageService, utilService) {
        this.url = "aspects/";
        this.httpClientService = httpClientService;
        this.storageService = storageService;
        this.utilService = utilService;
    },
    getAspects: function () {
        return this.httpClientService.get(this.url + 'list');
    },

    getAspect: function (aspectId) {
        return this.httpClientService.get(this.url + aspectId);
    },
    deleteAspect: function (aspectId) {
        return this.httpClientService.get(this.url + 'delete/' + aspectId);
    },
    getQuadrants: function (aspectSearchVm, storageKey) {
        return this.storageService.get(storageKey).then(storedResult => {
            if (!storedResult) {
                return this.httpClientService.post(this.url + 'quadrants', aspectSearchVm).then(HCombinations => {
                    var result = [];
                    for (var key in HCombinations) {
                        var aspects = HCombinations[key];
                        for (var aspectKey in aspects) {
                            var aspect = aspects[aspectKey];
                            aspect.degreeVM = this.utilService.getDegreeVM(aspect.distance);
                            //console.log('aspect.distance', aspect.distance);
                            aspect.degree = this.getDegree(aspect.degreeVM);
                            //console.log('cardEnum', CardEnum[aspect.sourceCard]);
                            aspect.normalizedDistance = aspect.distance / (CardEnum[aspect.sourceCard] * CardEnum[aspect.matchCard]);
                            //aspect.normalizedDegreeVM = this.utilService.getDegreeVM(aspect.normalizedDistance);
                            aspect.normalizedOrb = this.getNormalizedOrb(aspect.normalizedDistance);
                            aspect.sourcePlanet = aspect.sourcePlanetVM.name;
                            aspect.matchPlanet = aspect.matchPlanetVM.name;
                            aspect.sourceHouse = aspect.sourcePlanetVM.house;
                            aspect.matchHouse = aspect.matchPlanetVM.house;

                            aspect.rowKey = aspect.sourceCard + '_' + aspect.sourcePlanet + '_' + aspect.matchCard + '_' + aspect.matchPlanet;
                            var sourcePlanetLocation = aspect.sourcePlanetVM.location;
                            aspect.sourcePlanetLocation = this.utilService.getSign(sourcePlanetLocation);
                            aspect.sourceSign = sourcePlanetLocation.sign;

                            var matchPlanetLocation = aspect.matchPlanetVM.location;
                            aspect.matchPlanetLocation = this.utilService.getSign(matchPlanetLocation);
                            aspect.matchSign = matchPlanetLocation.sign;

                            result.push(aspect);
                        }
                    }
                    result.sort((a, b) => {
                        return a.normalizedOrb > b.normalizedOrb
                    });
                    var i = 0;
                    result.forEach(row => {
                        row.rowNr = ++i;
                        return row;
                    })


                    return this.storageService.set(storageKey, result).then(() => {
                        return result;
                    });
                })
            }
            return storedResult;
        });
    },
    getDegree: function (degreeVM) {
        return degreeVM.degree + ' ' + degreeVM.minute + ' ' + degreeVM.second
    },

    getNormalizedOrb: function (normalizedDistance) {
        var result = (normalizedDistance * 60).toFixed(8);
        return result;
    },
    update: function (aspectVm) {        
        aspectVm.properties = JSON.stringify(aspectVm.propertiesVM);
        return this.httpClientService.post(this.url, aspectVm);        
    }
}