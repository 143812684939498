export default {
    _slide_values_reverse: {},
    _slide_values: {},
    _slide_values_degree: {},
    _stepCount: 0,
    _zoom_slide_values_reverse: {},
    _zoom_slide_values: {},
    _zoom_slide_values_degree: {},
    _zoomStepCount: 0,
    init: function () {
        var start = 0.0;
        var end = 3.0;
        var step = 0;

        for (var i = start; i <= end; i++) {
            for (var f = 0.0; f < 0.60; f += 0.01) {
                if (((f * 100) % 10).toFixed(2) == 0 || (i < 1 && f < 0.20))
                {
                    var value = (i + f).toFixed(2);
                    var minute = 10.0 / 6.0 * f;
                    this._slide_values[step] = value;
                    this._slide_values_reverse[value] = step;
                    this._slide_values_degree[value] = i + minute;
                    step++;
                    if (i == end)
                    {
                        break;
                    }
                }
            }
        }
        this._stepCount = step - 1; // zero is no real step for the range slider
        console.log('stepCount', this._stepCount);
//        console.log('slide_values', this._slide_values);
//        console.log('slide_values_reverse', this._slide_values_reverse);
//        console.log('slide_values_degree', this._slide_values_degree);
        this._initZoom();
    },

    _initZoom: function () {
        var start = 0.0;
        var end = 1.0;
        var step = 0;

        for (var i = start; i <= end; i++) {
            for (var f = 0.0; f < 0.60; f += 0.01) {
//                if (((f * 100) % 4).toFixed(2) == 0 || (i < 1 && f < 0.29))
                {
                    var value = (i + f).toFixed(2);
                    var minute = 10.0 / 6.0 * f;
                    this._zoom_slide_values[step] = value;
                    this._zoom_slide_values_reverse[value] = step;
                    this._zoom_slide_values_degree[value] = i + minute;
                    step++;
                    if (i == end)
                    {
                        break;
                    }
                }
            }
        }
        this._zoomStepCount = step - 1;// zero is no real step for the range slider
//        console.log('zoomStepCount', this._zoomStepCount);
//        console.log('zoom_slide_values', this._zoom_slide_values);
//        console.log('zoom_slide_values_reverse', this._zoom_slide_values_reverse);
//        console.log('zoom_slide_values_degree', this._zoom_slide_values_degree);
    },
    getStepCount: function (zoom) {
        return zoom ? this._zoomStepCount : this._stepCount;
    },
    getStringValue: function (value) {
        var s = value.toString();
        if (s.indexOf('.') < 0) {
            s += '.';
        }
        return s.padEnd(4, 0);
    },

    getSlideValues(zoom) {
        return zoom ? this._zoom_slide_values : this._slide_values;
    },
    getSlideValuesReverse(zoom) {
        return zoom ? this._zoom_slide_values_reverse : this._slide_values_reverse;
    },
    getSlideValuesDegree(zoom) {
        return zoom ? this._zoom_slide_values_degree : this._slide_values_degree;
    }
}