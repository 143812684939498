import {CardEnum, ExportTypeEnum, HarmonicsEnum, SignatureTypeEnum, QuadrantEnum, PlanetEnum} from '@/constants/enums';
import {AspectSearchVM} from '@/models/aspectSearchVM';
import Constants from '@/constants/constants';
import {OriginsStateService} from '@/services/originsStateService';



export default{

    init: function (storageService, utilService, exportService, signatureService, signatureGroupingService, aspectTemplateService, aspectsService, signatureResultService, signatureLookupService) {
        this.storageService = storageService;
        this.utilService = utilService;
        this.exportService = exportService;
        this.signatureService = signatureService;
        this.signatureGroupingService = signatureGroupingService;
        this.aspectTemplateService = aspectTemplateService;
        this.aspectsService = aspectsService;
        this.signatureResultService = signatureResultService;
        this.signatureLookupService = signatureLookupService;
    },

    syncAspectExports: function (aspectId, originsStateService) {
        return this.exportService.list(aspectId, null, true).then(originsExportRows => {
            var exportedSignatures = {};
            var exportedAspects = {};
            var promises = [];
            originsExportRows.forEach(row => {
                var properties = JSON.parse(row.properties);
                if (row.exportType == ExportTypeEnum.Signature) {
                    exportedSignatures[row.rowKey] = {
                        signatureTarget: row.signatureTarget,
                        signatureType: row.signatureType,
                        filter: properties.filter
                    }
                } else {
                    exportedAspects[row.rowKey + '_' + row.signatureTarget] = {
                        signatureTarget: row.signatureTarget,
                        properties: properties
                    };
                }
            });
            if (originsExportRows.length > 0) {
                var promise = this.aspectsService.getAspect(aspectId).then(aspect => {
                    var promises2 = [];
                    if (!originsStateService) {
                        originsStateService = new OriginsStateService(aspect);
                    }
                    var aspectTemplateId = originsStateService.aspectTemplateForOrigins;
                    if (!aspectTemplateId) {
                        promises2.push(this.aspectTemplateService.getDefaultAspectTemplateId().then(x => {
                            aspectTemplateId = x;
                        }));
                    }
                    return Promise.all(promises2).then(() => {
                        return this.aspectTemplateService.getAspectTemplate(aspectTemplateId).then(aspectTemplate => {//                
                            originsStateService.aspectTemplate = aspectTemplate;
                            return this.signatureLookupService.loadAspect(aspect, aspectTemplate).then(() => {
                                var promises3 = [];
                                if (Object.keys(exportedSignatures).length > 0) {
                                    promises3.push(this._reExportSignatures(originsStateService, exportedSignatures, aspect.chartX, aspect.chartY, aspectTemplateId, aspectId));
                                }
                                if (Object.keys(exportedAspects).length > 0) {
                                    promises3.push(this._reExportAspects(originsStateService, exportedAspects, aspect.chartX, aspect.chartY, aspectTemplateId, aspectId));
                                }
                                return Promise.all(promises3).then(() => {
                                    console.log('promises3 done');
                                    return true;
                                });
                            });
                        });
                    });

                });
                promises.push(promise);
            }
            return Promise.all(promises);
        });
    },

    _reExportAspects: function (originsStateService, exportedAspects, chartX, chartY, aspectTemplateId, aspectId) {
        var promises = [];

        var signatureTargets = [];
        for (var key in exportedAspects) {
            if (signatureTargets.indexOf(exportedAspects[key].signatureTarget) < 0) {
                signatureTargets.push(exportedAspects[key].signatureTarget);
            }
        }

        signatureTargets.forEach(signatureTarget => {

            //          var highlightAspects = originsStateService.getHighlightAspects(signatureTarget);
            var aspectNotes = originsStateService.getGridAspectNotes(signatureTarget);
            var flexChartX = this.signatureService.getChartX(signatureTarget, chartX, chartY);
            var flexChartY = this.signatureService.getChartY(signatureTarget, chartX, chartY);
            promises.push(this._getAspects(flexChartX, flexChartY, aspectTemplateId).then(aspects => {
                var promises2 = [];
                aspects.forEach(aspect => {
                    var exportRow = exportedAspects[aspect.rowKey + '_' + signatureTarget];
                    var props = exportRow ? exportRow.properties : null;
                    if (props) {

                        var note = '';
                        if (aspectNotes) {
                            note = aspectNotes[aspect.rowKey];
                        }

                        props.row = aspect;
                        props.note = note;

                        var data = {
                            aspectId: aspectId,
                            signatureTarget: signatureTarget,
                            signatureType: SignatureTypeEnum.NotSet,
                            properties: JSON.stringify(props),
                            exportType: ExportTypeEnum.Aspects,
                            rowKey: aspect.rowKey
                        };
                        promises2.push(this.exportService.update(data).then(() => {
                            console.log('aspect export update done');
                            return true;
                        }));
                    }
                });
                return Promise.all(promises2).then(() => {
                    console.log('all aspect updates done');
                    return true;
                });
            }));

        });
        return Promise.all(promises).then(() => {
            console.log("_reExportAspects done");
            return true;
        });
    },

    _reExportSignatures: function (originsStateService, exportedSignatures, chartX, chartY, aspectTemplateId, aspectId) {
        var aspectTemplate = originsStateService.aspectTemplate;
        var promises = [];
        //console.log('exportedSignatures', exportedSignatures);
        var signatureTypes = [];
        var signatureTargets = [];

        for (var key in exportedSignatures) {
            if (signatureTypes.indexOf(exportedSignatures[key].signatureType) < 0) {
                signatureTypes.push(exportedSignatures[key].signatureType);
            }
            if (signatureTargets.indexOf(exportedSignatures[key].signatureTarget) < 0) {
                signatureTargets.push(exportedSignatures[key].signatureTarget);
            }
        }
        signatureTargets.forEach(signatureTarget => {
            var signatures = this.signatureResultService.getSignatures(signatureTarget, aspectTemplate);
            signatures = signatures.filter(signature => {
                return signatureTypes.indexOf(signature.signatureType) > -1;
            });
            signatures.forEach(signature => {
                //chartX, chartY, signatureId, aspectTemplateId, signatureTargetEnum
                var storageKey = this.signatureResultService.getSignatureResultStorageKey(chartX, chartY, signature.id, aspectTemplateId, signatureTarget);
                var promise = this.signatureService.getSignatureResultFromStorage(signature, storageKey, aspectTemplateId).then(result => {
                    var promises2 = [];
                    //result = this.filterSignatureResultRows(result, signature.signatureType);
                    result = this.signatureGroupingService.process(result, signature.signatureType);

                    var signatureNotes = originsStateService.getSignatureNotes(signature.target);
//                    var highlightSignatures = originsStateService.getHighlightSignatures(signature.target, signature.id);

                    result.forEach(signatureRows => {

                        var originalRowKeys = signatureRows[0].originalRowKeys.filter(key => {
                            return exportedSignatures[key] != null;
                        });

                        if (originalRowKeys) {
                            originalRowKeys.forEach(originalRowKey => {
                                if (exportedSignatures[originalRowKey] && exportedSignatures[originalRowKey].signatureTarget == signature.target
                                        && exportedSignatures[originalRowKey].signatureType == signature.signatureType
                                        ) {

                                    var signatureRowsCopy = this.utilService.cloneObject(signatureRows).map(row => {
                                        delete row.linkedFormations;
                                        delete row.originalRowKeys;
                                        return row;
                                    });

                                    var filter = exportedSignatures[originalRowKey].filter;
                                    var selectedCards = filter ? filter.selectedCards : null;
                                    var selectedPlanets = filter ? filter.selectedPlanets : null;
                                    var selectedQuadrants = filter ? filter.selectedQuadrants : null;
                                    var outerLimit = filter ? filter.outerLimit : null;

                                    if (!selectedCards) {
                                        selectedCards = originsStateService.selectedCards;
                                    }
                                    if (!selectedPlanets) {
                                        selectedPlanets = originsStateService.selectedPlanets;
                                    }
                                    if (!selectedQuadrants) {
                                        selectedQuadrants = originsStateService.selectedQuadrants;
                                    }
                                    if (!outerLimit) {
                                        outerLimit = originsStateService.outerLimit;
                                    }
                                    //outerLimit is minute/degree/second, convert to decimal
                                    outerLimit = this.utilService.getDoubleFromDegree(outerLimit);

                                    if (selectedCards && selectedCards.length > 0 && selectedCards.length < Object.keys(CardEnum).length) {
                                        signatureRowsCopy = signatureRowsCopy.filter(row => {
                                            return selectedCards.indexOf(row.cardEnum) > -1;
                                        });
                                    }
                                    if (selectedPlanets && selectedPlanets.length > 0 && selectedPlanets.length < Object.keys(PlanetEnum).length) {
                                        signatureRowsCopy = signatureRowsCopy.filter(row => {
                                            return selectedPlanets.indexOf(row.planetEnum) > -1;
                                        });
                                    }
                                    if (selectedQuadrants && selectedQuadrants.length > 0 && selectedQuadrants.length < Object.keys(QuadrantEnum).length) {
                                        signatureRowsCopy = signatureRowsCopy.filter(row => {
                                            return row.aspect == HarmonicsEnum.CJ || selectedQuadrants.indexOf('Q' + row.quadrant) >= 0; //first row has no quadrant
                                        });
                                    }
                                    signatureRowsCopy = signatureRowsCopy.filter(row => {
                                        return row.aspect == HarmonicsEnum.CJ || row.distance <= outerLimit;
                                    });

                                    //console.log('signatureRowsCopy length after', signatureRowsCopy.length);
                                    signatureRowsCopy[0].rowKey = originalRowKey;

                                    var props = {};
                                    var notes = {};
                                    var hasNotes = this.signatureService.getHasNotes(signatureRowsCopy, signatureNotes);
                                    if (hasNotes && signatureNotes) {
                                        for (var rowKey2 in hasNotes) {
                                            notes[rowKey2] = signatureNotes[rowKey2];
                                        }
                                    }
                                    props.rows = signatureRowsCopy;
                                    props.hasNotes = hasNotes;
                                    props.signatureNotes = notes;
                                    props.filter = filter;

                                    var data = {
                                        aspectId: aspectId,
                                        signatureTarget: signature.target,
                                        signatureType: signature.signatureType,
                                        properties: JSON.stringify(props),
                                        exportType: ExportTypeEnum.Signature,
                                        rowKey: originalRowKey
                                    };
                                    promises2.push(this.exportService.update(data).then(() => {
                                        console.log('signature export update done');
                                        return true;
                                    }));
                                }
                            });
                        }
                    });
                    return Promise.all(promises2).then(() => {
                        console.log('all signatures updates done');
                        return true;
                    });
                });
                promises.push(promise);
            });
        });

        return Promise.all(promises).then(() => {
            console.log("_reExportSignatures done");
            return true;
        });
    },

    _getAspects: function (chartX, chartY, aspectTemplateId) {
        var aspectSearchVM = new AspectSearchVM();
        aspectSearchVM.chartX = chartX;
        aspectSearchVM.chartY = chartY;
        aspectSearchVM.aspectTemplateId = aspectTemplateId;
        //aspectSearchVM.outerLimit = this.outerLimit;
        aspectSearchVM.outerLimit = Constants.outerLimit;
        //aspectSearchVM.cardEnums = this.selectedCards;
        aspectSearchVM.cardEnums = Object.keys(CardEnum);
        aspectSearchVM.aspectTimeIndexId = null;
        var storageKey = Constants.quadrantStoragePrefix + '_' + chartX + "_" + chartY + "_" + aspectTemplateId;
        return this.aspectsService.getQuadrants(aspectSearchVM, storageKey);
    }

}