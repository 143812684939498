
export default {

    init: function (storageService, httpClientService) {
        this.url = "origins/";
        this.storageService = storageService;
        this.httpClientService = httpClientService;
    },

    testGet: function () {        
        return this.httpClientService.get(this.url + 'testget').then(data => {
            return data;
        });
    },

    testPost: function () {
        var postData = {hoi: 'dinges'};
        return this.httpClientService.post(this.url + 'testpost', postData).then(data => {
            return data;
        });
    }
}


