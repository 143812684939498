
export default{

    init: function (storageService, httpClientService) {
        this.url = "export/";
        this.storageService = storageService;
        this.httpClientService = httpClientService;
    },

    getExport: function (id) {
        return this.httpClientService.get(this.url + id);
    },

    deleteExport: function (id) {
        return this.httpClientService.get(this.url + 'delete/' + id);
    },

    update: function (data) {        
        return this.httpClientService.post(this.url, data);
    },
    updateNr: function (id, nr) {
        var url = this.url + "updatenr?id=" + id + "&nr=" + nr;
        return this.httpClientService.get(url);
    },
    list: function (aspectId, originsExportListId, allFields) {
        var url = this.url + 'list';        
        var data = {
            aspectId: aspectId, 
            originsExportListId:originsExportListId,
            allFields:allFields
        };
        return this.httpClientService.post(url,data);
    }
}