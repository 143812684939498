
export const StorageEnum = {
    PersonIdentifier: 'p',
    StartupArgs: 'StartupArgs',
    ChartChecksum: "chartChecksum",
    Chart: "chart",
    DetailsBoxCollapsed: 'detailsbox_collapsed',
    CurrentSignature: 'currentSignature',
    GrandStarButtonStates: 'grandStarButtonStates'
}

export const FormationType = {
    Trine: 1,
    GrandCross: 2,
    TSquare: 3,
    GrandStar: 4
}

export const SettingsKeys = {
    OriginsGridPageSize: 'origins-gridPageSize',
    OriginsExportLabels: 'origins-exportlabels',
    BenMals: 'benMals'
}


export const GridRowColEnum = {
    Quadrant: 1,
    SourceCard: 2,
    SourcePlanet: 3,
    Aspect: 4,
    MatchCard: 5,
    MatchPlanet: 6,
    Distance: 7,
    NormalizedOrb: 8,
    SourceSign: 9,
    MatchSign: 10,
    SourceHouse: 11,
    MatchHouse: 12
}

export const CardEnum = {//filled by enumService

}

export const CardEnumKeys = {

}

export const AllKeysLookup = {
    H0: 'a',
    H1: 'b',
    H9: 'c',
    H10: 'd',
    H20: 'e',
    H24: 'f',
    H27: 'g',
    H108: 'h',
    HCC: 'i',
    AC: "j",
    MO: "k",
    ME: "l",
    VE: "m",
    JU: "n",
    NE: "o",
    SU: "p",
    MA: "q",
    SA: "r",
    KE: "s",
    RA: "t",
    UR: "u",
    PL: "v",
    CJ: 'z',
    OP: 'A',
    TR: 'B',
    TR1: 'C',
    TR2: 'D',
    SQ: 'E',
    SQ1: 'F',
    SQ2: 'G',
    QX: 'I', //H is reserved
    QX1: 'J',
    QX2: 'K',
    SX: 'L',
    SX1: 'M',
    SX2: 'N',
    QT: 'O',
    QT1: 'P',
    QT2: 'Q',
    QT3: 'R',
    QT4: 'S',
    XX: 'T',
    '-X': 'U'
            //IMPORTANT!! IF ANY HARMONICS, (PLANETS) OR ASPECTS ARE ADDED, THEY NEED TO GET THEIR UNIQUE MAPPING HERE AS WELL
            //continue with caps, then numbers and remaining ascii
}

export const AllKeysLookupReverse = {

}

export const PlanetEnum = {
    AC: "AC",
    MO: "MO",
    ME: "ME",
    VE: "VE",
    JU: "JU",
    NE: "NE",
    SU: "SU",
    MA: "MA",
    SA: "SA",
    KE: "KE",
    RA: "RA",
    UR: "UR",
    PL: "PL"
}

export const HarmonicsEnum = {
    CJ: "CJ",
    OP: "OP",
    TR: "TR",
    SQ: "SQ",
    QX: "QX",
    SX: "SX"
}

export const QuadrantEnum = {
    Q1: "Q1",
    Q2: "Q2",
    Q3: "Q3",
    Q4: "Q4"
}

export const PersonRoleEnum = {
    User: "User",
    Admin: "Admin",
    Developer: "Developer"
}


export const MainMenuTabEnum = {
    Profile: "Profile",
    Chart: "Chart",
    Locations: "Locations",
    Aspects: "Aspects",
    Manage: "Manage",
    Origins: "Origins"
}


export const MessageTypeEnum = {
    NavigateTo: 'NavigateTo',
    Reload: 'Reload',
    InfoForApp: 'InfoForApp'
}

export const SignatureTargetEnum = {
    ChartX_ChartY: "ChartX_ChartY",
    ChartY_ChartY: "ChartY_ChartY",
    ChartX_ChartX: "ChartX_ChartX",
    ChartY_ChartX: "ChartY_ChartX"
}

export const SignatureTargetEnumValues = {
    ChartX_ChartY: 1,
    ChartY_ChartY: 2,
    ChartX_ChartX: 3,
    ChartY_ChartX: 4
}


export const SignatureTypeEnumValues = {

}

export const SignatureTypeEnumValuesReverse = {

}

export const SignatureTypeEnum = {

}

export const ExportTypeEnum = {
    Signature: 'Signature',
    Aspects: 'Aspects'
}

export const SignEnum = {
    AR: {nr: 1, name: 'aries'},
    TA: {nr: 2, name: 'taurus'},
    GE: {nr: 3, name: 'gemini'},
    CA: {nr: 4, name: 'cancer'},
    LE: {nr: 5, name: 'leo'},
    VI: {nr: 6, name: 'virgo'},
    LI: {nr: 7, name: 'libra'},
    SC: {nr: 8, name: 'scorpio'},
    SG: {nr: 9, name: 'sagittarius'},
    CP: {nr: 10, name: 'capricorn'},
    AQ: {nr: 11, name: 'aquarius'},
    PI: {nr: 12, name: 'pisces'}
}



export const PlanetEnumNames = {
    AC: "ascendant",
    MO: "moon",
    ME: "mercury",
    VE: "venus",
    JU: "jupiter",
    NE: "neptune",
    SU: "sun",
    MA: "mars",
    SA: "saturn",
    KE: "ketu",
    RA: "rahu",
    UR: "uranus",
    PL: "pluto"
}

export const ConnectionFormationLinkEnum = {
    Connected: 'connected',
    Current: 'current'
}

export const BenMalLevelEnum = {
    Global: 1,
    AspectTemplate: 2,
    AllCharts: 3,
    CurrentCharts: 4,
    CurrentFormation: 5
}
