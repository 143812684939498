import {BenMalLevelEnum, SettingsKeys} from '@/constants/enums';
export default {
    _originsStateService: null,
    aspectTemplateBenMals: {},
    init: function (settingsService) {
        this.settingsService = settingsService;
    },
    set originsStateService(value) {
        this._originsStateService = value;
        var aspectTemplateProperties = this._originsStateService.aspectTemplate.propertiesVm;
        this.aspectTemplateBenMals = {
            benefics: aspectTemplateProperties.benefics,
            malefics: aspectTemplateProperties.malefics,
            showBens: aspectTemplateProperties.showBens,
            showMals: aspectTemplateProperties.showMals,
            flipColors: aspectTemplateProperties.flipColors
        };
    },
    get originsStateService() {
        return this._originsStateService;
    },
    benMalsFilled: function (benMals) {
        return benMals
                && benMals.benefics
                && benMals.malefics
                && benMals.benefics.length > 0
                && benMals.malefics.length > 0;
    },
    /*
     Get it from lowest level up
     CurrentChart - originsstateservice
     AllCharts   - settingsService
     AspectTemplate
     */
    getAllLevelBenMals: function () {
        var result = {};
        var promises = [];
        if (this._originsStateService) {
            result[BenMalLevelEnum.CurrentCharts] = this._originsStateService.benMals;
        }

        promises.push(this.settingsService.getSettings().then(settings => {
            var benMalsString = settings[SettingsKeys.BenMals];
            if (benMalsString && benMalsString.length > 0) {
                var benMals = JSON.parse(benMalsString);
                if (this.benMalsFilled(benMals)) {
                    result[BenMalLevelEnum.AllCharts] = benMals;
                }
            }
        }));

        result[BenMalLevelEnum.AspectTemplate] = this.aspectTemplateBenMals;

        return Promise.all(promises).then(() => {
            return result;
        });
    },
};