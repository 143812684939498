
export class ListItem {

    constructor(key, value) {
        this.key = key;
        this.value = value;
        this.next = null;
    }

}
/*
 We will store degrees of planets in hashtable on (degree,sortedLinkedLists)
 every X degrees we will have a sortedLinkedList to group the values,
 */
export class SortedLinkedList {
    constructor(checkDuplicates) {
        this.firstItem = null;
        this.checkDuplicates = checkDuplicates;
    }
    checkDuplicate(item, newItem) {
        var duplicateFound = false;

        while (item && item.key == newItem.key) {
            var isDifferent = false;
            for (var k in item.value) {
//                console.log('checkDuplicate', item.value[k], newItem.value[k]);
                if (item.value[k] != newItem.value[k]) {
                    isDifferent = true;
                    break;
                }
            }
            if (!isDifferent) {
                duplicateFound = true;
                //              console.log('duplicate found', item, newItem);
                break;
            }
            item = item.next;
        }
        return duplicateFound
    }
    insert(key, value) {
        var newItem = new ListItem(key, value);
        if (!this.firstItem) {
            this.firstItem = newItem;
        } else {
            if (newItem.key < this.firstItem.key) {
                newItem.next = this.firstItem;
                this.firstItem = newItem;
            } else {
                var item = this.firstItem;
                while (item && item.next && newItem.key > item.next.key) {
                    item = item.next;
                }
                if (!this.checkDuplicates
                        || (!this.checkDuplicate(item, newItem) && !this.checkDuplicate(item.next, newItem))
                        ) {
                    newItem.next = item.next;
                    item.next = newItem;
                }
            }
        }
    }

    getRange(start, end) {
        var result = [];
        var item = this.firstItem;
        while (item && item.key < start) {
            item = item.next;
        }
        while (item && item.key <= end) {
            result.push({
                key: item.key,
                value: item.value
            });
            item = item.next;
        }
        return result;
    }

    getAll() {
        var result = [];
        var item = this.firstItem;
        while (item) {
            result.push({
                key: item.key,
                value: item.value
            });
            item = item.next;
        }

    }
}


