export default{
    init: function (httpClientService) {
        this.url = "settings/";
        this.httpClientService = httpClientService;
    },

    getSettings: function () {
        return this.httpClientService.get(this.url);
    },
    updateSettings: function (data) {
        return this.httpClientService.post(this.url, data);
    }
}