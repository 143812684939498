import {AspectPropertiesVM} from '@/models/aspectPropertiesVM';
import {SignatureTargetEnum} from '@/constants/enums';
import utilService from '@/services/utilService';

export class OriginsStateService {
    constructor(aspect, aspectTemplate) {
        this.rootKey = 'origins';
        aspect.propertiesVM = aspect.properties ? new AspectPropertiesVM(JSON.parse(aspect.properties)) : new AspectPropertiesVM(null);
        this.aspect = aspect;
        this._aspectTemplate = aspectTemplate;

        this.grid1SignatureNotes = this._shrinkRowKeys(this.grid1SignatureNotes);
        this.grid2SignatureNotes = this._shrinkRowKeys(this.grid2SignatureNotes);
        this.grid3SignatureNotes = this._shrinkRowKeys(this.grid3SignatureNotes);
        this.grid4SignatureNotes = this._shrinkRowKeys(this.grid4SignatureNotes);

        this.grid1HighlightSignatures = this._shrinkDeeperRowKeys(this.grid1HighlightSignatures);
        this.grid2HighlightSignatures = this._shrinkDeeperRowKeys(this.grid2HighlightSignatures);
        this.grid3HighlightSignatures = this._shrinkDeeperRowKeys(this.grid3HighlightSignatures);
        this.grid4HighlightSignatures = this._shrinkDeeperRowKeys(this.grid4HighlightSignatures);

//        console.log('grid1SignatureNotes', this.grid1SignatureNotes);
        console.log('selectedGrandStars', this.selectedGrandStarsV2);
//        console.log('grid2SignatureNotes', this.grid2SignatureNotes);
//        console.log('grid3SignatureNotes', this.grid3SignatureNotes);
//        console.log('grid4SignatureNotes', this.grid4SignatureNotes);
//        
//        console.log('grid1HighlightSignatures', this.grid1HighlightSignatures);
//        console.log('grid2HighlightSignatures', this.grid2HighlightSignatures);
//        console.log('grid3HighlightSignatures', this.grid3HighlightSignatures);
//        console.log('grid4HighlightSignatures', this.grid4HighlightSignatures);
//                
    }

    set aspectTemplate(value) {
        this._aspectTemplate = value;
    }
    get aspectTemplate() {
        return this._aspectTemplate;
    }
    _getAspectProperty(key) {
        var result = null;
        var propertiesVm = this.aspect ? this.aspect.propertiesVM : null;
        if (propertiesVm && propertiesVm[this.rootKey] && propertiesVm[this.rootKey][key] != null) {
            result = propertiesVm[this.rootKey][key];
        }
        return result;
    }

    _setAspectProperty(key, value) {
        this.aspect.propertiesVM[this.rootKey][key] = value;
    }
    _getAspectArray(key) {
        var result = this._getAspectProperty(key);
        return result && result.length > 0 ? result : null;
    }

    get sortCol() {
        return parseInt(this._getAspectProperty('gridSortCol'));
    }

    set sortCol(value) {
        this._setAspectProperty('gridSortCol', value);
    }

    get sortAsc() {
        var result = this._getAspectProperty('gridSortAsc');
        return result != null ? result : true;
    }

    set sortAsc(value) {
        this._setAspectProperty('gridSortAsc', value);
    }

    get outerLimit() {
        var result = 3.00;
        var outerLimit = this._getAspectProperty('outerLimit');
        if (outerLimit) {
            result = outerLimit;
        } else if (this._aspectTemplate && this._aspectTemplate.propertiesVm && this._aspectTemplate.propertiesVm.aspectSecondaryDegree) {
            //  console.log('aspectSecondaryDegree', aspectTemplate.propertiesVm.aspectSecondaryDegree, aspectTemplate.propertiesVm.aspectSecondaryMinute);
            result = parseFloat(this._aspectTemplate.propertiesVm.aspectSecondaryDegree + '.' + this._aspectTemplate.propertiesVm.aspectSecondaryMinute);
        }
        return result;
    }

    set outerLimit(value) {

        this._setAspectProperty('outerLimit', value);
    }

    get selectedPlanets() {
        return this._getAspectArray('selectedPlanetButtons');
    }

    set selectedPlanets(value) {
        this._setAspectProperty('selectedPlanetButtons', value);
    }

    get selectedHarmonics() {
        return this._getAspectArray('selectedHarmonicButtons');
    }

    set selectedHarmonics(value) {

        this._setAspectProperty('selectedHarmonicButtons', value);
    }

    get selectedQuadrants() {
        return this._getAspectArray('selectedQuadrantButtons');
    }

    set selectedQuadrants(value) {
        this._setAspectProperty('selectedQuadrantButtons', value);
    }

    get selectedCards() {
        var result = this._getAspectArray('selectedCardButtons');
        if (!result) {
            result = [];
            if (this._aspectTemplate && this._aspectTemplate.propertiesVm && this._aspectTemplate.propertiesVm.cardEnums) {
                //  console.log('aspectSecondaryDegree', aspectTemplate.propertiesVm.aspectSecondaryDegree, aspectTemplate.propertiesVm.aspectSecondaryMinute);
                result = this._aspectTemplate.propertiesVm.cardEnums;
            }
        }
        return result;
    }

    set selectedCards(value) {
        this._setAspectProperty('selectedCardButtons', value);
    }

    set gridScroll(value) {
        this._setAspectProperty('gridScroll', value);
    }

    get gridScroll() {
        return this._getAspectProperty('gridScroll');
    }

    set gridExtraColumns(value) {
        this._setAspectProperty('gridExtraColumns', value);
    }

    get gridExtraColumns() {
        return this._getAspectProperty('gridExtraColumns');
    }

    set gridShowHiddenRows(value) {
        this._setAspectProperty('gridShowHiddenRows', value);
    }

    get gridShowHiddenRows() {
        return this._getAspectProperty('gridShowHiddenRows');
    }

    set hiddenAspects(value) {
        this._setAspectProperty('hiddenAspects', value);
    }
    get hiddenAspects() {
        return this._getAspectProperty('hiddenAspects');
    }
    set highlightAspects(value) {
        this._setAspectProperty('highlightAspects', value);
    }
    get highlightAspects() {
        return this._getAspectProperty('highlightAspects');
    }

    set chartOneSelectedPlanets(value) {
        this._setAspectProperty('chartOneSelectedPlanets', value);
    }

    get chartOneSelectedPlanets() {
        return this._getAspectProperty('chartOneSelectedPlanets');
    }

    set gridHighlightsToTop(value) {
        this._setAspectProperty('gridHighlightsToTop', value);
    }

    get gridHighlightsToTop() {
        return this._getAspectProperty('gridHighlightsToTop');
    }

    set grid2HighlightsToTop(value) {
        this._setAspectProperty('grid2HighlightsToTop', value);
    }
    get grid2HighlightsToTop() {
        return this._getAspectProperty('grid2HighlightsToTop');
    }

    set grid3HighlightsToTop(value) {
        this._setAspectProperty('grid3HighlightsToTop', value);
    }

    get grid3HighlightsToTop() {
        return this._getAspectProperty('grid3HighlightsToTop');
    }

    set grid4HighlightsToTop(value) {
        this._setAspectProperty('grid4HighlightsToTop', value);
    }

    get grid4HighlightsToTop() {
        return this._getAspectProperty('grid4HighlightsToTop');
    }

    set grid1SignaturesHighlightsToTop(value) {
        this._setAspectProperty('grid1SignaturesHighlightsToTop', value);
    }

    get grid1SignaturesHighlightsToTop() {
        return this._getAspectProperty('grid1SignaturesHighlightsToTop');
    }

    set grid2SignaturesHighlightsToTop(value) {
        this._setAspectProperty('grid2SignaturesHighlightsToTop', value);
    }

    get grid2SignaturesHighlightsToTop() {
        return this._getAspectProperty('grid2SignaturesHighlightsToTop');
    }

    set grid3SignaturesHighlightsToTop(value) {
        this._setAspectProperty('grid3SignaturesHighlightsToTop', value);
    }

    get grid3SignaturesHighlightsToTop() {
        return this._getAspectProperty('grid3SignaturesHighlightsToTop');
    }

    set grid4SignaturesHighlightsToTop(value) {
        this._setAspectProperty('grid4SignaturesHighlightsToTop', value);
    }

    get grid4SignaturesHighlightsToTop() {
        return this._getAspectProperty('grid4SignaturesHighlightsToTop');
    }

    _mapSignatureTargetValue(signatureTargetEnum) {
        var result = 0;
        switch (signatureTargetEnum) {
            case SignatureTargetEnum.ChartX_ChartY:
                result = 1;
                break;
            case SignatureTargetEnum.ChartY_ChartY:
                result = 2;
                break;
            case SignatureTargetEnum.ChartX_ChartX:
                result = 3;
                break;
            case SignatureTargetEnum.ChartY_ChartX:
                result = 4;
                break;
        }
        return result;
    }
    setGridSelectedSignature(value, signatureTargetEnum) {
        var key = 'grid' + this._mapSignatureTargetValue(signatureTargetEnum) + 'SelectedSignature';
        console.log(key, value);
        this._setAspectProperty(key, value);
    }

    getGridSelectedSignature(signatureTargetEnum) {
        var key = 'grid' + this._mapSignatureTargetValue(signatureTargetEnum) + 'SelectedSignature';
        var result = this._getAspectProperty(key);
        console.log(key, result);
        return result;
    }

    set aspectTemplateForOrigins(value) {
        this._setAspectProperty('aspectTemplateForOrigins', value);
    }

    get aspectTemplateForOrigins() {
        return this._getAspectProperty('aspectTemplateForOrigins');
    }

    set grid1HighlightSignatures(value) {
        this._setAspectProperty('grid1HighlightSignatures', value);
    }

    get grid1HighlightSignatures() {
        return this._getAspectProperty('grid1HighlightSignatures');
    }

    set grid1AspectNotes(value) {
        this._setAspectProperty('grid1AspectNotes', value);
    }

    get grid1AspectNotes() {
        return this._getAspectProperty('grid1AspectNotes');
    }

    set grid2AspectNotes(value) {
        this._setAspectProperty('grid2AspectNotes', value);
    }

    get grid2AspectNotes() {
        return this._getAspectProperty('grid2AspectNotes');
    }

    set grid3AspectNotes(value) {
        this._setAspectProperty('grid3AspectNotes', value);
    }

    get grid3AspectNotes() {
        return this._getAspectProperty('grid3AspectNotes');
    }

    set grid4AspectNotes(value) {
        this._setAspectProperty('grid4AspectNotes', value);
    }

    get grid4AspectNotes() {
        return this._getAspectProperty('grid4AspectNotes');
    }

    set grid1SignatureNotes(value) {
        this._setAspectProperty('grid1SignatureNotes', value);
    }

    get grid1SignatureNotes() {
        return this._getAspectProperty('grid1SignatureNotes');
    }

    set grid2SignatureNotes(value) {
        this._setAspectProperty('grid2SignatureNotes', value);
    }

    set grid3SignatureNotes(value) {
        this._setAspectProperty('grid3SignatureNotes', value);
    }

    set grid4SignatureNotes(value) {
        this._setAspectProperty('grid4SignatureNotes', value);
    }

    get showGrid2() {
        return this._getAspectProperty('showGrid2');
    }

    set showGrid2(value) {
        this._setAspectProperty('showGrid2', value);
    }

    get grid2SignatureNotes() {
        return this._getAspectProperty('grid2SignatureNotes');
    }

    get grid3SignatureNotes() {
        return this._getAspectProperty('grid3SignatureNotes');
    }

    get grid4SignatureNotes() {
        return this._getAspectProperty('grid4SignatureNotes');
    }

    get signatureTargetEnum() {
        return this._getAspectProperty('signatureTargetEnum');
    }

    set signatureTargetEnum(value) {
        this._setAspectProperty('signatureTargetEnum', value);
    }

    set grid2SortCol(value) {
        this._setAspectProperty('grid2SortCol', value);
    }

    get grid2SortCol() {
        return this._getAspectProperty('grid2SortCol');
    }

    set grid3SortCol(value) {
        this._setAspectProperty('grid3SortCol', value);
    }

    get grid3SortCol() {
        return this._getAspectProperty('grid3SortCol');
    }

    set grid4SortCol(value) {
        this._setAspectProperty('grid4SortCol', value);
    }

    get grid4SortCol() {
        return this._getAspectProperty('grid4SortCol');
    }

    set grid2SortAsc(value) {
        this._setAspectProperty('grid2SortAsc', value);
    }

    get grid2SortAsc() {
        return this._getAspectProperty('grid2SortAsc');
    }

    set grid3SortAsc(value) {
        this._setAspectProperty('grid3SortAsc', value);
    }

    get grid3SortAsc() {
        return this._getAspectProperty('grid3SortAsc');
    }

    set grid4SortAsc(value) {
        this._setAspectProperty('grid4SortAsc', value);
    }

    get grid4SortAsc() {
        return this._getAspectProperty('grid4SortAsc');
    }

    set grid2Scroll(value) {
        this._setAspectProperty('grid2Scroll', value);
    }

    get grid2Scroll() {
        return this._getAspectProperty('grid2Scroll');
    }

    set grid3Scroll(value) {
        this._setAspectProperty('grid3Scroll', value);
    }

    get grid3Scroll() {
        return this._getAspectProperty('grid3Scroll');
    }

    set grid4Scroll(value) {
        this._setAspectProperty('grid4Scroll', value);
    }

    get grid4Scroll() {
        return this._getAspectProperty('grid4Scroll');
    }

    set grid2ExtraColumns(value) {
        this._setAspectProperty('grid2ExtraColumns', value);
    }

    get grid2ExtraColumns() {
        return this._getAspectProperty('grid2ExtraColumns');
    }

    set grid3ExtraColumns(value) {
        this._setAspectProperty('grid3ExtraColumns', value);
    }

    get grid3ExtraColumns() {
        return this._getAspectProperty('grid3ExtraColumns');
    }

    set grid4ExtraColumns(value) {
        this._setAspectProperty('grid4ExtraColumns', value);
    }

    get grid4ExtraColumns() {
        return this._getAspectProperty('grid4ExtraColumns');
    }

    set grid2ShowHiddenRows(value) {
        this._setAspectProperty('grid2ShowHiddenRows', value);
    }

    get grid2ShowHiddenRows() {
        return this._getAspectProperty('grid2ShowHiddenRows');
    }

    set grid3ShowHiddenRows(value) {
        this._setAspectProperty('grid3ShowHiddenRows', value);
    }

    get grid3ShowHiddenRows() {
        return this._getAspectProperty('grid3ShowHiddenRows');
    }

    set grid4ShowHiddenRows(value) {
        this._setAspectProperty('grid4ShowHiddenRows', value);
    }

    get grid4ShowHiddenRows() {
        return this._getAspectProperty('grid4ShowHiddenRows');
    }

    set grid2HiddenAspects(value) {
        this._setAspectProperty('grid2HiddenAspects', value);
    }

    get grid2HiddenAspects() {
        return this._getAspectProperty('grid2HiddenAspects');
    }

    set grid3HiddenAspects(value) {
        this._setAspectProperty('grid3HiddenAspects', value);
    }

    get grid3HiddenAspects() {
        return this._getAspectProperty('grid3HiddenAspects');
    }

    set grid4HiddenAspects(value) {
        this._setAspectProperty('grid4HiddenAspects', value);
    }

    get grid4HiddenAspects() {
        return this._getAspectProperty('grid4HiddenAspects');
    }

    set grid2HighlightAspects(value) {
        this._setAspectProperty('grid2HighlightAspects', value);
    }

    get grid2HighlightAspects() {
        return this._getAspectProperty('grid2HighlightAspects');
    }

    set grid3HighlightAspects(value) {
        this._setAspectProperty('grid3HighlightAspects', value);
    }

    get grid3HighlightAspects() {
        return this._getAspectProperty('grid3HighlightAspects');
    }

    set grid4HighlightAspects(value) {
        this._setAspectProperty('grid4HighlightAspects', value);
    }

    get grid4HighlightAspects() {
        return this._getAspectProperty('grid4HighlightAspects');
    }
    set grid2HighlightSignatures(value) {
        this._setAspectProperty('grid2HighlightSignatures', value);
    }

    get grid2HighlightSignatures() {
        return this._getAspectProperty('grid2HighlightSignatures');
    }

    set grid3HighlightSignatures(value) {
        this._setAspectProperty('grid3HighlightSignatures', value);
    }

    get grid3HighlightSignatures() {
        return this._getAspectProperty('grid3HighlightSignatures');
    }

    set grid4HighlightSignatures(value) {
        this._setAspectProperty('grid4HighlightSignatures', value);
    }

    get grid4HighlightSignatures() {
        return this._getAspectProperty('grid4HighlightSignatures');
    }
    set grid2ChartOneSelectedPlanets(value) {
        this._setAspectProperty('grid2ChartOneSelectedPlanets', value);
    }

    get grid2ChartOneSelectedPlanets() {
        return this._getAspectProperty('grid2ChartOneSelectedPlanets');
    }

    set grid3ChartOneSelectedPlanets(value) {
        this._setAspectProperty('grid3ChartOneSelectedPlanets', value);
    }

    get grid3ChartOneSelectedPlanets() {
        return this._getAspectProperty('grid3ChartOneSelectedPlanets');
    }

    set grid4ChartOneSelectedPlanets(value) {
        this._setAspectProperty('grid4ChartOneSelectedPlanets', value);
    }

    get grid4ChartOneSelectedPlanets() {
        return this._getAspectProperty('grid4ChartOneSelectedPlanets');
    }

    set gridImportHighlights(value) {
        this._setAspectProperty('gridImportHighlights', value);
    }
    get gridImportHighlights() {
        return this._getAspectProperty('gridImportHighlights');
    }

    set useDefaultSignatureLimits(value) {
        this._setAspectProperty('useDefaultSignatureLimits', value);
    }
    get useDefaultSignatureLimits() {
        return this._getAspectProperty('useDefaultSignatureLimits');
    }

    set adjustedOuterLimits(value) {
        this._setAspectProperty('adjustedOuterLimits', value);
    }
    get adjustedOuterLimits() {
        return this._getAspectProperty('adjustedOuterLimits');
    }
    set sortConnectingGrandStars(value) {
        this._setAspectProperty('sortConnectingGrandStars', value);
    }
    get sortConnectingGrandStars() {
        return this._getAspectProperty('sortConnectingGrandStars');
    }
    set gridPageSize(value) {
        this._setAspectProperty('gridPageSize', value);
    }
    get gridPageSize() {
        return parseInt(this._getAspectProperty('gridPageSize'));
    }

    set showGrandStars(value) {
        this._setAspectProperty('showGrandStars', value);
    }
    get showGrandStars() {
        return this._getAspectProperty('showGrandStars');
    }

    set selectedGrandStars(value) {
        this._setAspectProperty('selectedGrandStarsV2', value);
    }

    get selectedGrandStars() {
        return this._getAspectProperty('selectedGrandStarsV2');
    }

    set benMals(value) {
        this._setAspectProperty('benMals', value);
    }
    get benMals() {
        return this._getAspectProperty('benMals');
    }    
    set filterH108Aspects(value) {
        this._setAspectProperty('filterH108Aspects', value);
    }
    get filterH108Aspects() {
        return this._getAspectProperty('filterH108Aspects');
    }

    getHighlightAspects(signatureTargetEnum) {
        var result = null;
        switch (signatureTargetEnum) {
            case SignatureTargetEnum.ChartX_ChartY:
                result = this.highlightAspects;
                break;
            case SignatureTargetEnum.ChartY_ChartY:
                result = this.grid2HighlightAspects;
                break;
            case SignatureTargetEnum.ChartX_ChartX:
                result = this.grid3HighlightAspects;
                break;
            case SignatureTargetEnum.ChartY_ChartX:
                result = this.grid4HighlightAspects;
                break;
        }
        return result;
    }

    getGridAspectNotes(signatureTargetEnum) {
        var result = {};
        switch (signatureTargetEnum) {
            case SignatureTargetEnum.ChartX_ChartY:
                result = this.grid1AspectNotes;
                break;
            case SignatureTargetEnum.ChartY_ChartY:
                result = this.grid2AspectNotes;
                break;
            case SignatureTargetEnum.ChartX_ChartX:
                result = this.grid3AspectNotes;
                break;
            case SignatureTargetEnum.ChartY_ChartX:
                result = this.grid4AspectNotes;
                break;
        }
        return result;
    }

    getSignatureNotes(signatureTargetEnum) {
        var result = {};
        switch (signatureTargetEnum) {
            case SignatureTargetEnum.ChartX_ChartY:
                result = this.grid1SignatureNotes;
                break;
            case SignatureTargetEnum.ChartY_ChartY:
                result = this.grid2SignatureNotes;
                break;
            case SignatureTargetEnum.ChartX_ChartX:
                result = this.grid3SignatureNotes;
                break;
            case SignatureTargetEnum.ChartY_ChartX:
                result = this.grid4SignatureNotes;
                break;
        }
        if (!result) {
            result = {};
        }
        return result;
    }

    getHighlightSignatures(signatureTargetEnum, signatureId) {
        var result = {};
        switch (signatureTargetEnum) {
            case SignatureTargetEnum.ChartX_ChartY:
                result = this.grid1HighlightSignatures;
                break;
            case SignatureTargetEnum.ChartY_ChartY:
                result = this.grid2HighlightSignatures;
                break;
            case SignatureTargetEnum.ChartX_ChartX:
                result = this.grid3HighlightSignatures;
                break;
            case SignatureTargetEnum.ChartY_ChartX:
                result = this.grid4HighlightSignatures;
                break;
        }
        result = result[signatureId];
        if (!result) {
            result = {};
        }
        return result;
    }
    _shrinkRowKeys(dict) {
        var result = {};
        if (dict) {
            for (var key in dict) {
                result[utilService.shrinkRowKey(key)] = dict[key];
            }
        }
        return result;
    }
    _shrinkDeeperRowKeys(dict) {
        if (dict) {
            for (var key in dict) {
                dict[key] = this._shrinkRowKeys(dict[key]);
            }
        }
        return dict;
    }
    reset() {
        this.aspect.propertiesVM.clear();
    }

}
