
export class AspectSearchVM {
    constructor() {
        this.chartX = null;
        this.chartY = null;
        this.aspectTemplateId = null;
        this.outerLimit = null;
        this.cardEnums = null;
        this.aspectTimeIndexId = null;
    }
}