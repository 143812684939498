import { alertController } from '@ionic/vue';
import { EnumResourceStrings } from '@/constants/enumresourcestrings';
//import LoginComponent from '@/popups/login';
//import router from '@/router';
//import {StorageEnum} from '@/constants/enums';

export default  {

    init: function (languageService) {
        this.languageService = languageService;
        this.initialized = false;
        this.strYes = null;
        this.strNo = null;
        this.strOK = null;
    },

    initMessages: function ()
    {
        var promises = [];
        if (!this.initialized) {
            promises.push(this.languageService.getText(EnumResourceStrings.Yes).then(x => this.strYes = x));
            promises.push(this.languageService.getText(EnumResourceStrings.No).then(x => this.strNo = x));
            promises.push(this.languageService.getText(EnumResourceStrings.OK).then(x => this.strOK = x));
        }

        return Promise.all(promises).then(() => {
            this.initialized = true;
        });
    },
    inputBox: function (message, placeHolder, callback) {
        return this.initMessages().then(() => {
            return alertController.create({
                //header: 'Confirm',
                message: message,
                inputs: [{
                        name: 'input',
                        value: placeHolder
                    }, ],
                buttons: [
                    {
                        text: this.strOK,
                        handler: callback,
                        cssClass: 'primary',
                    }
                ]
            }).then(alert => {
                return alert.present();
            });
        });
    },
    presentChoice: function (message, button1Text, button2Text, callback1, callback2) {
        return alertController.create({
            //header: 'Confirm',
            message: message,
            buttons: [
                {
                    text: button1Text,
                    //  role: 'cancel',
                    cssClass: 'secondary',
                    handler: callback1
                }, {
                    text: button2Text,
                    handler: callback2,
                    cssClass: 'primary',
                }
            ]
        }).then(alert => {
            return alert.present();
        });
    },
    confirm: function (message, callback) {
        return this.initMessages().then(() => {
            return this.presentChoice(message, this.strNo, this.strYes, null, callback);
        });
    },

    alert: function (message, callback) {
        return this.initMessages().then(() => {
            return alertController.create({
//header: 'Confirm',
                message: message,
                buttons: [
                    {
                        text: this.strOK,
                        handler: callback,
                        cssClass: 'primary',
                    }
                ]
            }).then(alert => {
                return alert.present();
            });
        });
    },

//    showLoginPopup: function () {
//        var props = {
//            showBackdrop: true,
//            cssClass: 'transparent-modal login-modal',
//            component: LoginComponent
//        };
//        modalController.create(props).then(popup => {
//            popup.present();
//            popup.onDidDismiss().then(x => {
//                if (x.role == "backdrop") {//escape button or backdrop clicked, so no data passed in callback
//                } else {
//                    if (x.data && x.data.success) {
//                        this.showLogin = false;
//                        this.settingsService.loadSettings().then(() => {
//                            //TODO get latest locationcombinations
//                            this.storageService.get(StorageEnum.StoreUserProfilesInDb).then(storeUserProfilesInDb => {
//                                if (storeUserProfilesInDb) {
//                                    //get latest stored profiles
//                                    this.userProfileService.getLatest().then(data => {
//                                        if (data.succeeded && data.userProfile) {
//                                            var json = {result: data.userProfile.content};
//                                            var storageService = this.storageService;
//                                            this.importService.doImportFile(false, [json], function () {
//                                                storageService.isLoggingOut = true;
//                                                router.replace('/tabs/settings/loggedin');
//                                            });
//                                        }
//                                    });
//                                }
//
//                            });
//                        });
//                    }
//                }
//            });
//
//        });
//    }
}

