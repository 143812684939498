import Constants from '@/constants/constants';
import {CardEnum, SignatureTypeEnum, SignatureTargetEnum, AllKeysLookup} from '@/constants/enums';

export default{
    init: function (storageService, httpClientService, utilService) {
        this.url = "signature/";
        this.storageService = storageService;
        this.httpClientService = httpClientService;
        this.utilService = utilService;
        this.signatures = {};
    },

    loadSignatureResult: function (signature, aspectTemplateId, chartX, chartY, storageKey) {
        var result = null;
        switch (signature.signatureType) {
            case SignatureTypeEnum.SquaringGrandCross:
            case SignatureTypeEnum.SealOfSolomon:
                break;
            default:
                return this._loadSignatureResult(signature, aspectTemplateId, chartX, chartY, storageKey).then(x => {
                    //console.log('loadSignatureResult done', storageKey);
                    return x;
                });
        }
        return result;
    },

    _getModulus: function (signatureType) {
        var result = 0;
        switch (signatureType) {
            case SignatureTypeEnum.GrandTrine:
                result = 120;
                break;
            case SignatureTypeEnum.GrandCross:
                result = 90;
                break;
            case SignatureTypeEnum.T_Square:
                result = 45;
                break;
            case SignatureTypeEnum.Quintile:
                result = 72;
                break;
            case SignatureTypeEnum.SealOfSolomon:
                result = 60;
                break;
            case SignatureTypeEnum.SquaringGrandCross:
                result = 45;
                break;
            case SignatureTypeEnum.Stellium:
                result = 0;
                break;
        }
        return result;
    },
    getSignatureResultFromStorage: function (signature, storageKey, aspectTemplateId) {
        var result = null;
        var modulus = this._getModulus(signature.signatureType);
        switch (signature.signatureType) {
            case SignatureTypeEnum.SquaringGrandCross:
                result = this._getExtendedFormation(signature, storageKey, aspectTemplateId, SignatureTypeEnum.GrandCross, modulus);
                break;
            case SignatureTypeEnum.SealOfSolomon:
                result = this._getExtendedFormation(signature, storageKey, aspectTemplateId, SignatureTypeEnum.GrandTrine, modulus);
                break;
            default:
                result = this.storageService.storageGet(storageKey);
        }
        return result;
    },

    _getExtendedFormation: function (signature, storageKey, aspectTemplateId, orgSignatureType, modulus) {

        var orgStorageKey = storageKey.replace('_' + signature.id + '_', '_' + this.signatures[aspectTemplateId + '_' + orgSignatureType].id + '_');

        return this.storageService.storageGet(orgStorageKey).then(originalRows => {
            //console.log(orgStorageKey);
            if (!originalRows) {
                console.log('storageKey absent', orgStorageKey, storageKey);
            }

            var rows = originalRows.filter(x => {
                return x[0].linkedFormations; //only rows with linkedformations
            });
            //console.log('getExtendedFormation', signature.signatureType, rows.length);

            var result = [];
            //two combinedrows at a time
            var rowNr = 0;
            var leftPostFix = AllKeysLookup[Constants.X_POSTFIX];
            rows.forEach(sourceRow => {
                sourceRow[0].linkedFormations.forEach(index => {
                    var targetRow = originalRows[index];
                    if (targetRow) {
                        var newRow = [];
                        var firstRow = true;
                        sourceRow.forEach(row => {
                            if (firstRow) {
                                firstRow = false;
                                row = this.utilService.copySimpleObject(row);//clone so rowkey won't exponentially grow
                                row.rowKey += targetRow[0].rowKey;
                                row.index = rowNr++;
                            }
                            newRow.push(row);
                        });
                        firstRow = true;
                        targetRow.forEach(row => {
                            if (firstRow) {
                                firstRow = false;
                                row.isX = 1;
                                if (!row.childKey.endsWith(leftPostFix)) {
                                    row.childKey += leftPostFix;
                                    ``
                                }
                                var diff = row.degree - sourceRow[0].degree;
                                if (diff < 0) {
                                    diff += 360;
                                }
                                diff %= modulus;
                                row.degreeNegative = false;
                                if (diff > (modulus / 2)) {
                                    diff = Math.abs(diff - modulus);
                                    row.degreeNegative = true;
                                }
                                row.distance = diff;

                                var degreeVM = this.utilService.getDegreeVM(row.distance);


                                row.distanceDegree = degreeVM.degree + ' ' + degreeVM.minute + ' ' + degreeVM.second;
                            }
                            newRow.push(row);
                        });
                        result.push(newRow);
                    }
                });
            });

            return result;
        });
    },

    _loadSignatureResult: function (signature, aspectTemplateId, chartX, chartY, storageKey) {
        this.signatures[aspectTemplateId + '_' + signature.signatureType] = signature;
        var modulus = this._getModulus(signature.signatureType);
        return this.storageService.get(storageKey).then(storedSignatureResult => {

            if (!storedSignatureResult) {
                var postData = {
                    signatureId: signature.id,
                    aspectTemplateId: aspectTemplateId,
                    chartX: chartX,
                    chartY: chartY,
                    outerLimit: Constants.outerLimit,
                    cardEnums: Object.keys(CardEnum)
                };
                return this.httpClientService.post(this.url + 'signatureresult', postData).then(data => {
                    if (data.succeeded) {
                        var result = data.result.map(signatureRow => {
                            var rowKey = '';
                            var firstRowDegree = 0.0;
                            var rows = Object.keys(signatureRow).map(key => {
                                var row = signatureRow[key];
                                row.aspect = key;
                                var degreeVM = this.utilService.getDegreeVM(row.distance);
                                row.distanceDegree = degreeVM.degree + ' ' + degreeVM.minute + ' ' + degreeVM.second;
                                if (rowKey) { //first row is CJ
                                    rowKey += '_' + key + '_';
                                } else {
                                    firstRowDegree = row.degree;
                                }
                                var childKey = row.cardEnum + '_' + row.planetEnum;
                                if (row.isX) {
                                    rowKey += Constants.X_PREFIX;
                                    childKey += Constants.X_POSTFIX;
                                }
                                rowKey += row.cardEnum + '_' + row.planetEnum;


                                row.childKey = this.utilService.shrinkRowKey(childKey);
                                row.sign = row.planetLocation.sign;
                                row.planetLocation = this.utilService.getSign(row.planetLocation);

                                if (firstRowDegree) {
                                    var diff = row.degree - firstRowDegree;                                    
                                    if (diff < 0) {
                                        diff += 360;
                                    }
                                    diff %= modulus;

                                    row.degreeNegative = false;
                                    if (diff > (modulus / 2)) {
                                        diff = Math.abs(diff - modulus);
                                        row.degreeNegative = true;
                                    }
                                }

                                //row.normalizedDegreeVM = this.utilService.getDegreeVM(row.normalizedDistance);
                                return row;
                            });
                            rowKey = this.utilService.shrinkRowKey(rowKey);
                            rows[0].rowKey = rowKey;
                            return rows;
                        });


                        result.sort((a, b) => {
                            return a[0].normalizedDistance > b[0].normalizedDistance
                        });
                        var i = 0;
                        result.forEach(rows => {
                            rows[0].index = i++;
                            return rows;
                        });

                        switch (signature.signatureType) {
                            case SignatureTypeEnum.GrandCross:
                                result = this._linkFormations(result, 90, 45);
                                break;
                            case SignatureTypeEnum.GrandTrine:
                                result = this._linkFormations(result, 120, 60);
                                break;
                        }

                        return this.storageService.storageSet(storageKey, result).then(() => {
                            console.log('storageSet done ' + storageKey);
                            return result;
                        });
                    }
                });
            }
            return new Promise((resolve/*, reject*/) => {
//                console.log('resolve(storedSignatureResult)', storageKey);
                resolve(storedSignatureResult);
            });
        });

    },

    _linkFormations: function (matches, modulus, desiredDiff) {
        var length = matches.length;
        for (var e = 0; e < length; e++) {
            var currentMatch = matches[e];
            var currentRow = currentMatch[0];
            var currentDegree = currentRow.degree;
            var currentRowId = currentRow.index;
            for (var i = 0; i < currentRowId; i++) {
                var targetMatch = matches[i];
                var targetRow = targetMatch[0];
                var targetDegree = targetRow.degree;
                var diff = Math.abs(targetDegree - currentDegree);
                diff %= modulus;
                if (diff <= desiredDiff + Constants.outerLimit && diff >= desiredDiff - Constants.outerLimit) {
                    if (!targetRow.linkedFormations) {
                        targetRow.linkedFormations = [];
                    }
                    targetRow.linkedFormations.push(currentRowId);
                }
            }
        }
        return matches;
    },

    /*    getSignaturePassed: function (signatureId, aspectTemplateId, chartX, chartY, outerLimit, cardEnums) {
     var data = {
     signatureId: signatureId,
     aspectTemplateId: aspectTemplateId,
     chartX: chartX,
     chartY: chartY,
     outerLimit: outerLimit,
     cardEnums: cardEnums
     };
     return this.httpClientService.post(this.url + 'signatureresultpassed', data);
     },
     */

    getRowClasses: function (signatureRows, highlightSignatures, currentSignatureRowKey, connectedSignatures, loadTrigger) {
        var result = {};
        var mainRowKey = this.utilService.shrinkRowKey(signatureRows[0].rowKey);

        var first = true;
        signatureRows.forEach(row => {
            var rowKey = mainRowKey;

            if (!first) {
                rowKey += this.utilService.shrinkRowKey(row.childKey);
            }
            var css = "";
            if (currentSignatureRowKey === rowKey) {
                css = "selected";
            }

            if (highlightSignatures) {
                var highlightIndex = highlightSignatures[rowKey];
                if (highlightIndex) {
                    css += " highlight-" + highlightIndex;
                }
            }
            if (connectedSignatures) {
                var connected = connectedSignatures[rowKey];
                if (connected && connected.hasLeft) {
                    css += " " + Constants.cssClassLeftSignature;
                }
                if (connected && connected.hasRight) {
                    css += " " + Constants.cssClassRightSignature;
                }
            }
            if (loadTrigger) {
                css += " trigger-" + loadTrigger;
            }
            if (css) {
                result[rowKey] = css;
            }
            first = false;
        });

        return result;
    },

    getHasNotes: function (signatureRows, signatureNotes) {
        var result = {};
        var mainRowKey = signatureRows[0].rowKey;
        var first = true;
        signatureRows.forEach(row => {
            var rowKey = mainRowKey;

            if (!first) {
//                rowKey = mainRowKey + '_' + row.cardEnum + '_' + row.planetEnum;
//                if (row.isX) {
//                    rowKey += Constants.X_PREFIX;
//                }

                rowKey += row.childKey;

            }
            if (signatureNotes && signatureNotes[rowKey]) {
                result[rowKey] = true;
            }
            first = false;
        });
        return result;
    },

    getChartX: function (signatureTargetEnum, chartX, chartY) {
        var result = null;
        switch (signatureTargetEnum) {
            case SignatureTargetEnum.ChartX_ChartY:
            case SignatureTargetEnum.ChartX_ChartX:
                result = chartX;
                break;
            case SignatureTargetEnum.ChartY_ChartY:
            case SignatureTargetEnum.ChartY_ChartX:
                result = chartY;
                break;
        }
        return result;
    },
    getChartY: function (signatureTargetEnum, chartX, chartY) {
        var result = null;
        switch (signatureTargetEnum) {
            case SignatureTargetEnum.ChartX_ChartY:
            case SignatureTargetEnum.ChartY_ChartY:
                result = chartY;
                break;
            case SignatureTargetEnum.ChartX_ChartX:
            case SignatureTargetEnum.ChartY_ChartX:
                result = chartX;
                break;
        }
        return result;
    },

}