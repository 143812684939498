import { Http } from '@capacitor-community/http';
import configService from './configService';
import {StorageEnum} from '@/constants/enums';

const _apiRootUrl = configService.getApiRootUrl();
export default{

    init: function (storageService) {
        this.storageService = storageService;
    },
    get: function (url) {
        return this.storageService.get(StorageEnum.PersonIdentifier).then(token => {
            var headers = {};
            if (token) {
                headers['Authorization'] = 'Basic ' + token;
            }
            return Http.request(
                    {
                        url: _apiRootUrl + url,
                        method: 'GET',
                        headers: headers
                    }).then(x => {
                return x.status == 200 ? x.data : null;
            });
        });

    },
    post: function (url, data, accepts) {

        if (url.indexOf('-') === 0) {
            url = _apiRootUrl.substring(0, _apiRootUrl.length - 1) + url;
        } else {
            url = _apiRootUrl + url
        }
        return this.storageService.get(StorageEnum.PersonIdentifier).then(token => {
            var headers = {'Content-Type': 'application/json'};
            if (token) {
                headers['Authorization'] = 'Basic ' + token;                
            }
            var options = {
                url: url,
                data: data,
                headers: headers
            };
            if (accepts) {
                options.accepts = accepts;
            }
            return Http.request({...options, method: 'POST'}).then(x => {
                return x.status == 200 ? x.data : null;
            });
        });

    }
}