import { createApp } from 'vue'
import App from './App.vue'
import router from './router';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */

import './theme/variables.scss';
import './theme/bootstrap.min.css';
import './theme/global.scss';
import {StorageEnum} from '@/constants/enums';


import storageService from '@/services/storageService';
import languageService from '@/services/languageService';
import dialogService from '@/services/dialogService';
import utilService from '@/services/utilService';
import configService from '@/services/configService';
import httpClientService from '@/services/httpclientService';
import authenticationService from '@/services/authenticationService';
import originsService from '@/services/originsService';
import aspectsService from '@/services/aspectsService';
import chartService from '@/services/chartService';
import aspectTemplateService from '@/services/aspectTemplateService';
import sliderService from '@/services/sliderService';
import settingsService from '@/services/settingsService';
import messageService from '@/services/messageService';
import personService from '@/services/personService';
import signatureService from '@/services/signatureService';
import signatureGroupingService from '@/services/signatureGroupingService';
import enumService from '@/services/enumService';
import exportService from '@/services/exportService';
import exportSyncService from '@/services/exportSyncService';
import signatureLookupService from '@/services/signatureLookupService';
import signatureResultService from '@/services/signatureResultService';
import benMalService from '@/services/benMalService';

const app = createApp(App)
        .use(IonicVue)
        .use(router);

storageService.createStorage().then(() => {    
    router.isReady().then(() => {
        httpClientService.init(storageService);
        messageService.init(configService);
        authenticationService.init(storageService, httpClientService, messageService);
        authenticationService.authenticate().then(authenticationData => {            
                        
            var uri = authenticationData ? authenticationData.uri : null;
            if (!uri || uri === '/logout') {                
                authenticationService.logout();
            } else {
                enumService.init(httpClientService).then(() => {
                    languageService.init(configService.selectedLanguage, storageService, httpClientService);
                    dialogService.init(languageService);

                    originsService.init(storageService, httpClientService);
                    aspectsService.init(httpClientService, storageService, utilService);
                    aspectTemplateService.init(httpClientService);
                    chartService.init(httpClientService, storageService);
                    sliderService.init();
                    settingsService.init(httpClientService);

                    personService.init(httpClientService);
                    signatureService.init(storageService, httpClientService, utilService);

                    signatureGroupingService.init(utilService);
                    signatureResultService.init(utilService, signatureService, signatureGroupingService);
                    signatureLookupService.init(utilService, signatureService, signatureGroupingService, signatureResultService);
                    exportService.init(storageService, httpClientService);
                    exportSyncService.init(storageService, utilService, exportService, signatureService, signatureGroupingService, aspectTemplateService, aspectsService, signatureResultService, signatureLookupService);
                    benMalService.init(settingsService);

                    app.provide("storageService", storageService);
                    app.provide("languageService", languageService);
                    app.provide("dialogService", dialogService);
                    app.provide("utilService", utilService);
                    app.provide("configService", configService);
                    app.provide("httpClientService", httpClientService);
                    app.provide("authenticationService", authenticationService);
                    app.provide("originsService", originsService);
                    app.provide("aspectsService", aspectsService);
                    app.provide("chartService", chartService);
                    app.provide("aspectTemplateService", aspectTemplateService);
                    app.provide("sliderService", sliderService);
                    app.provide("settingsService", settingsService);
                    app.provide("messageService", messageService);
                    app.provide("personService", personService);
                    app.provide("signatureService", signatureService);
                    app.provide("signatureGroupingService", signatureGroupingService);
                    app.provide("signatureResultService", signatureResultService);
                    app.provide("exportService", exportService);
                    app.provide("exportSyncService", exportSyncService);
                    app.provide("signatureLookupService", signatureLookupService);
                    app.provide("benMalService", benMalService);

//    const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
//    storageService.get(StorageEnum.DarkMode).then(x => {
//        var dark = prefersDark.matches;
//        if (x != null) {
//            dark = x;
//        }
//        utilService.toggleDarkTheme(dark);
//    });
//
//    // Listen for changes to the prefers-color-scheme media query
//    prefersDark.addListener((mediaQuery) => utilService.toggleDarkTheme(mediaQuery.matches));

                    var promises = [];
                    //console.log(authenticationData);
                    if (authenticationData.args) {
                        promises.push(storageService.set(StorageEnum.StartupArgs, JSON.parse(authenticationData.args)));
                    }
                    app.mount('#app');
                    //console.log(uri);
                    Promise.all(promises).then(() => {
                        
                        router.replace(uri);
                    });

                });
            }

        });
    });


});

  