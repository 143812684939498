import {SignatureTypeEnum} from '@/constants/enums';
/* 
 grouping of signatures:
 map<SourceCardPlanet, Map<Aspect, Map<TargetCardPlanet> > >
 */

export default{

    init: function (utilService) {
        this.utilService = utilService;
    },

    process: function (signatures, signatureType) {
        var result = this.groupSignatureResults(signatures, signatureType);
        result = this.flattenGroupedSignatures(result);
        result.forEach(signatureRows => {
            var skip = 0;
            var normalizedDistance = 0.0;
            signatureRows.filter(() => ++skip > 1).forEach(row => {
                normalizedDistance += row.normalizedDistance;
            });
            signatureRows[0].normalizedDistance = normalizedDistance / (signatureRows.length - 1);
        });
        return result;
    },

    groupSignatureResults: function (signatures, signatureType) {

        var result = {};
        var secondCount = 0;
        switch (signatureType) {
            case SignatureTypeEnum.SealOfSolomon:
                secondCount = 3;
                break;
            case SignatureTypeEnum.SquaringGrandCross:
                secondCount = 4;
                break;
        }

        signatures.forEach(signature => {
            var first = true;
            var sourceRowKey = '';
            var counter = 1;
            signature.forEach(row => {
                var key = row.cardEnum + '_' + row.planetEnum;
                if (first) {
                    //combined signatures also need second CJ or the results get mangled.
                    var secondCJ = null;
                    if (secondCount > 0) {
                        secondCJ = signature[secondCount];
                    }
                    if (secondCJ) {
                        key += ':' + secondCJ.cardEnum + '_' + secondCJ.planetEnum;
                    }
                    sourceRowKey = key;
                    var firstItem = result[sourceRowKey];
                    if (!firstItem) {
                        firstItem = {
                            row: row,
                            originalRowKeys: [],
                            aspects: {}
                        };
                    }
                    firstItem.originalRowKeys.push(row.rowKey);
                    result[key] = firstItem;
                    first = false;
                } else {
                    var aspectKey = row.aspect;
                    if (secondCount > 0 && counter > secondCount) {
                        aspectKey += '-2';
                    }
                    var aspects = result[sourceRowKey].aspects[aspectKey];
                    if (!aspects) {
                        aspects = {
                            aspect: row.aspect,
                            rows: {}
                        };
                    }
                    aspects.rows[key] = row;
                    result[sourceRowKey].aspects[aspectKey] = aspects;
                }
                counter++;
            });
        });
        return result;
    },

    flattenGroupedSignatures: function (groupedSignatures) {
        var result = [];
        for (var key in groupedSignatures) {
            var signature = [];
            var groupedSignature = groupedSignatures[key];
            var sourceRow = groupedSignature.row;
            sourceRow.originalRowKeys = groupedSignature.originalRowKeys;
            signature.push(sourceRow);
            for (var aspectKey in groupedSignature.aspects) {
                //            console.log('aspectKey', aspectKey);
                var aspect = groupedSignature.aspects[aspectKey];
                for (var rowKey in aspect.rows) {
                    var row = aspect.rows[rowKey];
                    signature.push(row);
                }
            }
            result.push(signature);
        }
        return result;
    }

}
