import {StorageEnum} from '@/constants/enums';

export default {
    init: function (storageService, httpClientService, messageService) {
        this.service = "-auth";
        this.storageService = storageService;
        this.httpClientService = httpClientService;
        this.messageService = messageService;
    },
    authenticate: function () {
        return this.storageService.set(StorageEnum.PersonIdentifier, null).then(() => {
            //http://starcheckionic.luckfigures.com/index.html#d5725667-bb6b-440a-9aed-1061108832da
            var url = window.location.href;
            var rx = /(.*)\/index.html#(?<id>.*)$/;
            var groups = rx.exec(url).groups;
            var key = groups['id'];
            console.log('key', key);
            return this.httpClientService.post(this.service, {key: key}).then(x => {
                //let data = JSON.parse(x);
                var data = {};
                if (x && x.result) {
                    data = JSON.parse(x.result.properties);
                    return this.storageService.set(StorageEnum.PersonIdentifier, data.jwt).then(() => {
                        return data;
                    });
                } else {
                    return data;
                }
            });
        });

    },
    logout: function () {
        return this.storageService.clear(true).then(() => {
            this.messageService.navigateParent('/dologout');
        });
    }
}